import React, { useEffect } from "react";
import httpService from "../../services/httpService";
import { useHistory, useParams } from "react-router-dom";

const VerifyEmail = () => {
  const { userId, token } = useParams();
  const history = useHistory();

  useEffect(() => {
    httpService
      .post(`${process.env.REACT_APP_SERVER_URL}authentication/confirmemail`, {
        userId: userId,
        token: token,
      })
      .then((res) => {
        history.push("/createaccount");
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <div className='text-center mt-5 mb-5'>
      <div className='spinner-border text-primary' role='status'>
        <p className='mt-5 mb-5'>Loading...</p>
        <span className='sr-only'>Loading...</span>
      </div>
    </div>
  );
};
export default VerifyEmail;

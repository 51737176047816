import React from "react";
import "./Terms.css";
import { useTranslation } from "react-i18next";

const Terms = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className='container-fluid m-4'>
      <div className='row'>
        <h2>{i18n.t("termsOfUse")}</h2>
      </div>
      <div className='row mt-2' style={{ width: "99%" }}>
        <p>{i18n.t("termsPar1")}</p>
      </div>
      <div className='row' style={{ width: "99%" }}>
        <p>{i18n.t("termsPar2")}</p>
      </div>
      <div className='row mt-3' style={{ width: "99%" }}>
        <h2>{i18n.t("vatRefund")}</h2>
      </div>
      <div className='row mt-2' style={{ width: "99%" }}>
        <p>{i18n.t("termsPar3")}</p>
      </div>
      <div className='row mt-3'>
        <h2>{i18n.t("personalInfo")}</h2>
      </div>
      <div className='row mt-2' style={{ width: "99%" }}>
        <p>{i18n.t("termsPar4")}</p>
      </div>
      <div className='row' style={{ width: "99%" }}>
        <p>{i18n.t("termsPar5")}</p>
      </div>
      <div className='row' style={{ width: "99%" }}>
        <p>{i18n.t("termsPar6")}</p>
      </div>
      <div className='row' style={{ width: "99%" }}>
        <p>{i18n.t("termsPar7")}</p>
      </div>
      <div className='row mt-3'>
        <h2>{i18n.t("generalProv")}</h2>
      </div>
      <div className='row mt-2' style={{ width: "99%" }}>
        <p>{i18n.t("termsPar8")}</p>
      </div>
      <div className='row' style={{ width: "99%" }}>
        <p>{i18n.t("termsPar9")}</p>
      </div>
      <div className='row' style={{ width: "99%" }}>
        <p>{i18n.t("termsPar10")}</p>
      </div>
    </div>
  );
};

export default Terms;

import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./wishList.css";
import httpService from "../../services/httpService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const WishList = () => {
  const { t, i18n } = useTranslation();

  const [change, setChange] = useState(0);

  const history = useHistory();
  const [wishData, setWishData] = useState([]);
  const [loader, setLoader] = useState(true);

  const [itemQuantityCart, setItemQuantityCart] = useState("");

  const loginFirst = () => toast(i18n.t("loginFirst"));
  const addToCart = () => toast(i18n.t("productCartAdded"));
  const deleteFromWishList = () => toast(i18n.t("removeWish"));
  const addQuantityFirst = () => toast(i18n.t("addQuantity"));
  const alreadyAddedToCart = () => toast(i18n.t("alreadyAddedToCart"));

  useEffect(() => {
    AOS.init({
      duration: 2200,
    });

    httpService
      .get(
        `${process.env.REACT_APP_SERVER_URL}UserSavedProducts/list?saveType=0`
      )
      .then((res) => {
        setWishData(res.data);
        setLoader(false);
      })
      .catch((err) => console.log(err));
  }, [change]);

  const removeFromWishList = (id) => {
    if (localStorage.getItem("token")) {
      httpService
        .delete(
          `${process.env.REACT_APP_SERVER_URL}UserSavedProducts/delete/${id}`
        )
        .then((res) => {
          setChange(change + 1);
          deleteFromWishList();
        })
        .catch((err) => console.log(err));
    } else {
      loginFirst();
    }
  };

  const openProductDetails = (productId) => {
    history.push({
      pathname: "/productdetails",
      state: { productId: productId },
    });
  };

  const handleAddToCart = (productId) => {
    if (localStorage.getItem("token")) {
      //if (itemQuantityCart !== "") {
      httpService
        .post(`${process.env.REACT_APP_SERVER_URL}UserSavedProducts/insert`, {
          productId: productId,
          quantity: Number(itemQuantityCart),
          saveTypeEnum: 1,
        })
        .then((res) => {
          setChange(change + 1);
          addToCart();
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            alreadyAddedToCart();
          } else {
            console.log(err);
          }
        });
      //}
      //  else {
      //   addQuantityFirst();
      // }
    } else {
      loginFirst();
    }
  };
  return (
    <div className='container'>
      <ToastContainer
        progressClassName='toastProgress'
        bodyClassName='toastBody'
        toastClassName='toastContainer'
      />

      <div className='row mt-5' data-aos='zoom-out'>
        <img alt='' src='/assets/wishList.png' width='100%' height='80%' />
      </div>
      {loader ? (
        <div className='text-center mt-5 mb-5'>
          <div className='spinner-border text-primary' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      ) : (
        <>
          {wishData.length === 0 ? (
            <div className='row justify-content-center mt-5 mb-5'>
              <button
                onClick={() => history.push("/")}
                className='continue-action'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='28'
                  height='28'
                  fill='#2F5FA0'
                  className='bi bi-bag-heart mt-1 mb-2'
                  style={{ alignSelf: "center" }}
                  viewBox='0 0 16 16'
                >
                  <path
                    fill-rule='evenodd'
                    d='M10.5 3.5a2.5 2.5 0 0 0-5 0V4h5v-.5Zm1 0V4H15v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4h3.5v-.5a3.5 3.5 0 1 1 7 0ZM14 14V5H2v9a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1ZM8 7.993c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132Z'
                  />
                </svg>
                <span style={{ color: "#2F5FA0" }}>{i18n.t("emptyWish")}</span>
              </button>
            </div>
          ) : (
            <div className='row mt-5' data-aos='fade-up'>
              <p style={{ fontWeight: "bold" }}>
                {i18n.t("savedLater")} ({wishData.length})
              </p>
            </div>
          )}

          {wishData.map((w, i) => (
            <div key={i} className='row mt-5 mb-5' data-aos='fade-up'>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-md-6'>
                    {w.product.mainImage !== null ? (
                      <img
                        alt=''
                        src={w.product.mainImage.path}
                        width='100%'
                        height='80%'
                        className='btnHover'
                        onClick={() => openProductDetails(w.product.id)}
                      />
                    ) : null}
                  </div>
                  <div className='col-md-6'>
                    <p
                      style={{ fontWeight: "bold" }}
                      className='btnHover'
                      onClick={() => openProductDetails(w.product.id)}
                    >
                      {w.product.name}
                    </p>
                    <p
                      className='btnHover'
                      onClick={() => openProductDetails(w.product.id)}
                    >
                      {w.product.brand}
                    </p>
                    <p
                      className='btnHover'
                      onClick={() => openProductDetails(w.product.id)}
                    >
                      {w.product.breif}
                    </p>
                    <p style={{ color: "darkred" }}>
                      {w.product.price} {i18n.t("EGP")}
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-md-6' style={{ textAlign: "end" }}>
                <div className='mt-3'>
                  {/* <input
                type='number'
                placeholder='Quantity'
                className='quantity-input'
                onChange={(e) => {
                  setItemQuantityCart(e.target.value);
                }}
              /> */}
                </div>
                <div className='mt-3 mr-4'>
                  <button
                    className='remove-btn w-25 btnClick'
                    onClick={() => removeFromWishList(w.id)}
                  >
                    {i18n.t("remove")}
                  </button>
                </div>
                <div className='mt-3 mr-4'>
                  <button
                    className='add-to-cart-btn w-25 btnClickWhite'
                    onClick={() => handleAddToCart(w.product.id)}
                  >
                    {i18n.t("addToCart")}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default WishList;

import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import httpService from "../../services/httpService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";

const ServiceList = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [services, setServices] = useState([]);
  const [servicesSize, setServicesSize] = useState(15);
  const [totalServicesCount, setTotalServicesCount] = useState(0);
  const [pageServicesNumber, setPageServicesNumber] = useState(0);
  const serviceNotValid = () => toast(i18n.t("serviceNotValid"));
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    AOS.init({
      duration: 2200,
    });
    httpService
      .post(`${process.env.REACT_APP_SERVER_URL}service/listpage`, {
        pageSize: servicesSize,
        pageNumber: 0,
      })
      .then((res) => {
        setServices(res.data["pageData"]);
        setTotalServicesCount(res.data.totalCount);
        setLoader(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const openServiceForm = (service) => {
    if (localStorage.getItem("token")) {
      history.push({
        pathname: "/servicerequest",
        state: { service: service },
      });
      window.scrollTo(0, 0);
    } else {
      serviceNotValid();
    }
  };

  const handlePaginationServicesClick = (e) => {
    let pageNum = e.selected;
    httpService
      .post(`${process.env.REACT_APP_SERVER_URL}service/listpage`, {
        pageSize: servicesSize,
        pageNumber: pageNum,
      })
      .then((res) => {
        setServices(res.data["pageData"]);
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className='container'>
      <ToastContainer
        progressClassName='toastProgress'
        bodyClassName='toastBody'
        toastClassName='toastContainer'
      />
      <div className='row mt-5' data-aos='fade-down'>
        <h5>{i18n.t("ourServices")}</h5>
      </div>

      {loader ? (
        <div className='text-center mt-5 mb-5'>
          <div className='spinner-border text-primary' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className='row mt-5 mb-5'>
            {services.map((s, i) => (
              <div
                key={i}
                className='col-md-2 mt-3 mr-1 ml-4 text-center'
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  borderRadius: 10,
                  border: "1px solid #E5E5E5",
                }}
              >
                <div
                  style={{
                    color: "black",
                  }}
                >
                  <p
                    className='text-center'
                    style={{ fontWeight: "bold", color: "#2F5FA0" }}
                  >
                    {s.name}
                  </p>
                  <p className='text-center'>{s.description}</p>

                  <button
                    onClick={() => openServiceForm(s)}
                    className='request-btn mt-3 mb-3 btnClick'
                  >
                    {i18n.t("requestService")}
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className='row mb-5'>
            <div className='col-md-8'></div>
            <div className='col-md-4'>
              <ReactPaginate
                previousLabel={i18n.t("prev")}
                nextLabel={i18n.t("next")}
                pageCount={Math.ceil(totalServicesCount / servicesSize)}
                onPageChange={(e) => handlePaginationServicesClick(e)}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ServiceList;

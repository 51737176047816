import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import httpService from "../../services/httpService";
import ReactPaginate from "react-paginate";

const AboutUs = () => {
  const { t, i18n } = useTranslation();
  const [partners, setPartners] = useState([]);
  const [teams, setTeam] = useState([]);
  const [pageSize, setPageSize] = useState(4);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    AOS.init({
      duration: 2200,
    });

    httpService
      .post(`${process.env.REACT_APP_SERVER_URL}partner/listpage`, {
        pageSize: 999,
      })
      .then((res) => {
        setPartners(res.data["pageData"]);
      })
      .catch((err) => console.log(err));
    httpService
      .post(`${process.env.REACT_APP_SERVER_URL}teammembers/listpage`, {
        pageSize: pageSize,
        pageNumber: 0,
      })
      .then((res) => {
        setTotalCount(res.data.totalCount);
        setTeam(res.data["pageData"]);
      })
      .catch((err) => console.log(err));
  }, []);

  const handlePaginationClick = (e) => {
    let pageNum = e.selected;
    httpService
      .post(`${process.env.REACT_APP_SERVER_URL}teammembers/listpage`, {
        pageSize: pageSize,
        pageNumber: pageNum,
      })
      .then((res) => {
        setTeam(res.data["pageData"]);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className='container'>
      <div className='row mt-5' data-aos='zoom-out'>
        <img alt='' src='/assets/aboutus.png' width='100%' height='80%' />
      </div>
      <div className='row mt-5' data-aos='fade-up'>
        <h4>{i18n.t("ourHistory")}</h4>
      </div>
      <div className='row' data-aos='fade-down'>
        <p>{i18n.t("historyDesc")}</p>
      </div>
      {/* <div className='row mt-5' data-aos='fade-up'>
        <h4>{i18n.t("ourCEO")}</h4>
      </div> */}
      {/* <div className='row mt-3' data-aos='fade-right'>
        <div className='col-md-6'>
          <p style={{ fontWeight: "bold" }}>{i18n.t("CEOName")}</p>
          <p>{i18n.t("CEOBrief")}</p>
        </div>
        <div className='col-md-6'>
          <img
            alt=''
            src={p.mainImage.path}
            width='80%'
            height='80%'
            className='ml-5  mt-1'
          />
        </div>
      </div> */}
      <div className='row mt-5' data-aos='fade-up'>
        <h4>{i18n.t("ourPartners")}</h4>
      </div>
      {partners.map((p, i) => (
        <div key={i} className='row mt-3' data-aos='fade-right'>
          <div className='col-md-6'>
            <p style={{ fontWeight: "bold" }}>{p.name}</p>
            <p>{p.description}</p>
          </div>
          <div className='col-md-6'>
            {p.mainImage !== null ? (
              <img
                alt=''
                src={p.mainImage.path}
                width='80%'
                height='80%'
                className='ml-5  mt-1'
              />
            ) : null}
          </div>
        </div>
      ))}

      <div className='row mt-5' data-aos='fade-up'>
        <p
          className='col-md-8'
          style={{ fontWeight: "bolder", fontSize: "22px" }}
        >
          {i18n.t("ourTeam")}
        </p>
        <div className='col-md-4'>
          <ReactPaginate
            previousLabel={i18n.t("<")}
            nextLabel={i18n.t(">")}
            pageCount={Math.ceil(totalCount / pageSize)}
            onPageChange={(e) => handlePaginationClick(e)}
            containerClassName={"pagination"}
            activeClassName={"active"}
            pageLinkClassName='hide-pagination'
          />
        </div>
      </div>

      <div className='row ' data-aos='zoom-out'>
        <p className='col-md-12'>{i18n.t("teamDesc")}</p>
        <div className='col-md-12 mb-5 mt-3'>
          <div className='row justify-content-center'>
            {teams.map((t, i) => (
              <div
                key={i}
                className='col-md-2 mr-5 mt-1'
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  borderRadius: 10,
                  border: "1px solid #E5E5E5",
                }}
              >
                {t.mainImage !== null ? (
                  <img
                    src={t.mainImage.path}
                    alt=''
                    height='70%'
                    width='100%'
                  />
                ) : null}
                <div
                  style={{
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  <p>{t.name}</p>
                  <p>{t.position}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;

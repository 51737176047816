import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { t } from "i18next";

const OrderSubmitted = (props) => {
  const history = useHistory();
  useEffect(() => {}, []);
  return (
    <div className='container'>
      <div className='row mt-5 mb-5'>
        <div className='col-md-4'></div>
        <div
          className='col-md-4'
          style={{ position: "relative", top: 0, left: 0 }}
        >
          <img
            alt=''
            src='/assets/blob.png'
            width='100%'
            height='100%'
            style={{
              position: "relative",
              top: 0,
              left: 0,
            }}
          />
          <img
            style={{
              position: "absolute",
              top: "30px",
              left: "30px",
            }}
            alt=''
            src='/assets/orderdevice.png'
            width='80%'
            height='100%'
          />
        </div>
        <div className='col-md-4'></div>
      </div>
      <div className='row justify-content-center m-5'>
        <h5>{t("thanks")} 💙</h5>
      </div>
      <div className='row justify-content-center m-5'>
        <p>{t("orderPlacedSuccessfully")}</p>
      </div>
      <div className='row justify-content-center m-5'>
        <button
          className='mb-3 w-25'
          style={{
            backgroundColor: "#FFFFFF",
            border: "1px solid #2F5FA0",
            color: "#2F5FA0",
            padding: ".5rem",
          }}
          onClick={() => history.push("/orders")}
        >
          {t("myOrders")}
        </button>
      </div>
    </div>
  );
};

export default OrderSubmitted;

import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  return (
    <div
      className='container mt-5 mb-5'
      style={{ fontWeight: "bold", fontSize: "24" }}
    >
      <div className='row justify-content-center mt-5 mb-5'>
        <button onClick={() => history.push("/")} className='continue-action'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='30'
            height='30'
            fill='darkred'
            className='bi bi-x-lg mt-3 mb-3'
            viewBox='0 0 16 16'
            style={{ alignSelf: "center" }}
          >
            <path
              fill-rule='evenodd'
              d='M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z'
            />
            <path
              fill-rule='evenodd'
              d='M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z'
            />
          </svg>
          <span style={{ color: "#2F5FA0" }}>{i18n.t("notFound")}</span>
        </button>
      </div>
    </div>
  );
};

export default NotFound;

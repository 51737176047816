import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const QRSubmitted = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  return (
    <div className='container mt-5 mb-5'>
      <div className='row justify-content-center mt-3'>
        <p className='mr-2' style={{ fontWeight: "bold", color: "#2F5FA0" }}>
          {i18n.t("goldenPartner")}
        </p>
      </div>
      <div className='row justify-content-center'>
        <img alt='' src='/assets/canon.png' style={{ width: 60, height: 40 }} />
      </div>
      <div className='row mt-5 mb-5'>
        <div className='col-md-4'></div>
        <div
          className='col-md-4'
          style={{ position: "relative", top: 0, left: 0 }}
        >
          <img
            alt=''
            src='/assets/blob.png'
            width='100%'
            height='100%'
            style={{
              position: "relative",
              top: 0,
              left: 0,
            }}
          />
          <img
            style={{
              position: "absolute",
              top: "30px",
              left: "30px",
            }}
            alt=''
            src='/assets/orderdevice.png'
            width='80%'
            height='100%'
          />
        </div>
        <div className='col-md-4'></div>
      </div>
      <div className='row justify-content-center mb-5'>
        <h5>{t("thanksComplain")} 💙</h5>
      </div>
      <div className='row justify-content-center mb-5'>
        <p style={{ color: "#2F5FA0" }}>{t("QRComplaint")}</p>
      </div>
      {/* <div className='row justify-content-center mb-5'>
        <button
          onClick={() => history.push("/")}
          className='edit-add-btn btnClickWhite'
        >
          {t("continueShop")}
        </button>
      </div> */}
    </div>
  );
};

export default QRSubmitted;

import React, { useEffect, useState, useContext } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./cartList.css";
import httpService from "../../services/httpService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
//import { OrderContext } from "../../context/orderContext";
import { useTranslation } from "react-i18next";

const blockInvalidChar = (e) =>
  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

export const CartList = () => {
  const { t, i18n } = useTranslation();

  //const [order, changeOrderDetails] = useContext(OrderContext);

  const history = useHistory();

  const [change, setChange] = useState(0);

  const [termsAccepted, setTermsAccepted] = useState(false);

  const [newQuantity, setNewQuantity] = useState();
  const [prdData, setPrdData] = useState();
  const [loader, setLoader] = useState(true);

  const [cartData, setCartData] = useState([]);
  const [calculated, setCalculated] = useState(false);

  let [totalItems, setTotalItems] = useState(0);
  let [subTotal, setSubTotal] = useState(0);
  let [vat, setVat] = useState(0);
  let [totalCost, setTotalCost] = useState(0);

  const makeOrder = () => toast(i18n.t("makeOrder"));
  const saveToWishList = () => toast(i18n.t("saveWish"));
  const deleteFromCart = () => toast(i18n.t("removeCart"));
  const alreadyAddedToWish = () => toast(i18n.t("alreadyAddedToWish"));
  const addQuantityToUpdate = () => toast(i18n.t("addQuantityToUpdate"));
  const updatedQuantity = () => toast(i18n.t("updatedQuantity"));
  const quantityNotZero = () => toast(i18n.t("quantityNotZero"));

  useEffect(() => {
    AOS.init({
      duration: 2200,
    });

    httpService
      .get(
        `${process.env.REACT_APP_SERVER_URL}UserSavedProducts/list?saveType=1`
      )
      .then((res) => {
        setCartData(res.data);
        let items = 0;
        res.data.forEach((p) => (items += p.quantity));
        setTotalItems(items);
        let sub = 0;
        res.data.forEach((p) => {
          if (p.product.hasDiscount === true)
            sub += p.quantity * (p.product.price - p.product.discountAmount);
          else sub += p.quantity * p.product.price;
        });
        setSubTotal((Math.round(sub * 100) / 100).toFixed(2));
        let v = (sub * 14) / 100;
        setVat((Math.round(v * 100) / 100).toFixed(2));
        let c = sub + v;
        setTotalCost((Math.round(c * 100) / 100).toFixed(2));
        setLoader(false);
      })
      .catch((err) => console.log(err));
  }, [change]);

  const addToWishtList = (productId) => {
    if (localStorage.getItem("token")) {
      httpService
        .post(`${process.env.REACT_APP_SERVER_URL}UserSavedProducts/insert`, {
          productId: productId,
          quantity: 1,
          saveTypeEnum: 0,
        })
        .then((res) => {
          setChange(change + 1);
          saveToWishList();
        })
        .catch((err) => {
          if (err.response.status === 403) {
            alreadyAddedToWish();
          } else {
            console.log(err);
          }
        });
    } else {
      makeOrder();
    }
  };

  const deleteItem = (id) => {
    if (localStorage.getItem("token")) {
      httpService
        .delete(
          `${process.env.REACT_APP_SERVER_URL}UserSavedProducts/delete/${id}`
        )
        .then((res) => {
          setChange(change + 1);
          deleteFromCart();
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((err) => console.log(err));
    } else {
      makeOrder();
    }
  };

  const handleMinueValue = (e) => {};

  const handleQuantityChange = (item, value) => {
    if (value !== "0") {
      setPrdData(item);
      setNewQuantity(value);
    } else {
      quantityNotZero();
    }
  };

  const submitQuantityChange = () => {
    if (Number(newQuantity) !== 0 && prdData) {
      httpService
        .put(`${process.env.REACT_APP_SERVER_URL}UserSavedProducts/edit`, {
          id: prdData.id,
          quantity: Number(newQuantity),
        })
        .then((res) => {
          setChange(change + 1);
          updatedQuantity();
        })
        .catch((err) => console.log(err));
    } else {
      addQuantityToUpdate();
    }
  };

  const handleCheckout = () => {
    if (localStorage.getItem("token")) {
      // changeOrderDetails({
      //   items: cartData.length,
      //   subTotal: subTotal,
      //   vat: vat,
      //   total: totalCost,
      //   address: "",
      //   phone: "",
      //   paymentMethod: "",
      // });
      history.push("/order");
    } else {
      makeOrder();
    }
  };

  const openProductDetails = (productId) => {
    history.push({
      pathname: "/productdetails",
      state: { productId: productId },
    });
  };

  const handleTermsChange = (checked) => {
    setTermsAccepted(checked);
  };

  return (
    <div className='container'>
      <ToastContainer
        progressClassName='toastProgress'
        bodyClassName='toastBody'
        toastClassName='toastContainer'
      />
      <div className='row mt-5' data-aos='zoom-out'>
        <img alt='' src='/assets/cartList.png' width='100%' height='80%' />
      </div>
      {loader ? (
        <div className='text-center mt-5 mb-5'>
          <div className='spinner-border text-primary' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      ) : (
        <>
          {cartData.length === 0 ? (
            <div className='row justify-content-center mt-5 mb-5'>
              <button
                onClick={() => history.push("/")}
                className='continue-action'
              >
                <img
                  alt=''
                  src='/assets/Cart.png'
                  width='40'
                  height='40'
                  className='mt-1 mb-2'
                  style={{ alignSelf: "center" }}
                />
                <span style={{ color: "#2F5FA0" }}>{i18n.t("emptyCart")}</span>
              </button>
            </div>
          ) : (
            <div className='row mt-5 mb-5'>
              <div className='col-md-6'>
                <p style={{ fontWeight: "bold" }} className='mb-3'>
                  {i18n.t("products")} ({cartData.length})
                </p>
                {cartData.map((c, i) => (
                  <div key={i} className='row mb-5'>
                    <div className='col-md-6'>
                      {c.product.mainImage !== null ? (
                        <img
                          alt=''
                          src={c.product.mainImage.path}
                          width='100%'
                          height='80%'
                          className='btnHover'
                          onClick={() => openProductDetails(c.product.id)}
                        />
                      ) : null}
                    </div>
                    <div className='col-md-6'>
                      <p
                        style={{ fontWeight: "bold" }}
                        className='btnHover'
                        onClick={() => openProductDetails(c.product.id)}
                      >
                        {c.product.name}
                      </p>
                      <p
                        className='btnHover'
                        onClick={() => openProductDetails(c.product.id)}
                      >
                        {c.product.brand}
                      </p>
                      <p
                        className='btnHover'
                        onClick={() => openProductDetails(c.product.id)}
                      >
                        {c.product.breif}
                      </p>
                      <p
                        className='btnHover'
                        onClick={() => openProductDetails(c.product.id)}
                      >
                        {c.quantity} {i18n.t("items")}
                      </p>
                      {c.product.hasDiscount === true ? (
                        <p style={{ color: "darkred" }}>
                          {(c.product.price - c.product.discountAmount) *
                            c.quantity}{" "}
                          {i18n.t("EGP")}
                        </p>
                      ) : (
                        <p style={{ color: "darkred" }}>
                          {c.product.price * c.quantity} {i18n.t("EGP")}
                        </p>
                      )}
                      <div className='row mb-3'>
                        <div className='col-md-4'>
                          <input
                            type='number'
                            min='1'
                            onKeyDown={blockInvalidChar}
                            className='form-control w-100'
                            placeholder='Q'
                            onKeyUp={(e) =>
                              handleQuantityChange(c, e.target.value)
                            }
                            onClick={(e) =>
                              handleQuantityChange(c, e.target.value)
                            }
                          />
                        </div>
                        <div className='col-md-8'>
                          <button
                            className='save-qty-btn p-2 btnClick'
                            onClick={submitQuantityChange}
                          >
                            {i18n.t("updateQty")}
                          </button>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-3 mr-2'>
                          <div
                            className='mr-2 btnHover'
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                            onClick={() => deleteItem(c.id)}
                          >
                            <img
                              className='mr-1 icon-edit-cart'
                              alt=''
                              src='/assets/Remove.png'
                              style={{ alignSelf: "center" }}
                            />
                            {i18n.t("remove")}
                          </div>
                        </div>
                        <div className='col-md-3'>
                          <div
                            className='btnHover'
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                            onClick={() => addToWishtList(c.product.id)}
                          >
                            <img
                              alt=''
                              src='/assets/Save.png'
                              style={{ alignSelf: "center" }}
                              className='mr-1 ml-1 icon-edit-cart'
                            />
                            {i18n.t("save")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className='col-md-6'>
                <p style={{ fontWeight: "bold" }} className='mb-3'>
                  {i18n.t("orderSummary")}
                </p>
                <div className='row'>
                  <div className='col-md-6'>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>{i18n.t("items")}</p>
                      {totalItems} {i18n.t("items")}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>{i18n.t("subtotal")}</p>
                      {(Math.round(subTotal * 100) / 100).toFixed(2)}{" "}
                      {i18n.t("EGP")}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>{i18n.t("vat")} (14%)</p>
                      {(Math.round(vat * 100) / 100).toFixed(2)} {i18n.t("EGP")}
                    </div>
                    <div
                      className='mt-5'
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>{i18n.t("total")}</p>
                      {(Math.round(totalCost * 100) / 100).toFixed(2)}{" "}
                      {i18n.t("EGP")}
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <input
                    type='checkbox'
                    value={termsAccepted}
                    onChange={(e) => handleTermsChange(e.target.checked)}
                  />
                  <h6 style={{ marginLeft: "1rem" }}>
                    {i18n.t("termsCheckBoxText")}{" "}
                    <span
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={() => {
                        history.push("/terms");
                        window.scrollTo(0, 0);
                      }}
                    >
                      {i18n.t("terms")}
                    </span>
                  </h6>
                </div>

                <button
                  onClick={handleCheckout}
                  className='w-100 checkout-btn btnClickWhite'
                  disabled={!termsAccepted}
                >
                  {i18n.t("checkout")}
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CartList;

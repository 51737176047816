import React, { useContext, useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import "./home.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import httpService from "../../services/httpService";
import { useHistory } from "react-router-dom";
import "./home.css";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Home = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const serviceNotValid = () => toast(i18n.t("serviceNotValid"));

  const [bestSellers, setBestSellers] = useState([]);
  const [pageSellerSize, setPageSellerSize] = useState(4);
  const [totalSellerCount, setTotalSellerCount] = useState(0);
  const [pageSellerNumber, setPageSellerNumber] = useState(0);

  const [newProducts, setNewProducts] = useState([]);
  const [pageNewSize, setPageNewSize] = useState(4);
  const [totalNewCount, setTotalNewCount] = useState(0);
  const [pageNewNumber, setPageNewNumber] = useState(0);

  const [services, setServices] = useState([]);
  const [servicesSize, setServicesSize] = useState(4);
  const [totalServicesCount, setTotalServicesCount] = useState(0);
  const [pageServicesNumber, setPageServicesNumber] = useState(0);

  const [categories, setCategories] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    AOS.init({
      duration: 2200,
    });
    httpService
      .post(`${process.env.REACT_APP_SERVER_URL}product/listpage`, {
        sort: 0,
        pageSize: pageNewSize,
        pageNumber: 0,
      })
      .then((res) => {
        setTotalNewCount(res.data.totalCount);
        setNewProducts(res.data["pageData"]);
      })
      .catch((err) => console.log(err));

    httpService
      .post(`${process.env.REACT_APP_SERVER_URL}product/listpage`, {
        sort: 1,
        pageSize: pageSellerSize,
        pageNumber: 0,
      })
      .then((res) => {
        setTotalSellerCount(res.data.totalCount);
        setBestSellers(res.data["pageData"]);
      })
      .catch((err) => console.log(err));

    httpService
      .post(`${process.env.REACT_APP_SERVER_URL}category/listpage`, {
        searchText: "",
        pageSize: 999,
      })
      .then((res) => {
        console.log("catcat", res);
        setCategories(res.data.pageData);
        setLoader(false);
      })
      .catch((err) => console.log(err));

    httpService
      .post(`${process.env.REACT_APP_SERVER_URL}service/listpage`, {
        pageSize: servicesSize,
        pageNumber: 0,
      })
      .then((res) => {
        console.log("services", res);
        setServices(res.data["pageData"]);
        setTotalServicesCount(res.data.totalCount);
      })
      .catch((err) => console.log(err));
  }, []);

  const openCategoryDetails = (category) => {
    history.push({
      pathname: "/category",
      state: { categoryId: category.id, categoryName: category.name },
    });
    window.scrollTo(0, 0);
  };

  const openProductDetails = (productId) => {
    history.push({
      pathname: "/productdetails",
      state: { productId: productId },
    });
    window.scrollTo(0, 0);
  };

  const handlePaginationSellerClick = (e) => {
    let pageNum = e.selected;
    httpService
      .post(`${process.env.REACT_APP_SERVER_URL}product/listpage`, {
        sort: 1,
        pageSize: pageSellerSize,
        pageNumber: pageNum,
      })
      .then((res) => {
        console.log("best", res);
        setBestSellers(res.data["pageData"]);
      })
      .catch((err) => console.log(err));
  };

  const handlePaginationNewClick = (e) => {
    let pageNum = e.selected;
    httpService
      .post(`${process.env.REACT_APP_SERVER_URL}product/listpage`, {
        sort: 0,
        pageSize: pageNewSize,
        pageNumber: pageNum,
      })
      .then((res) => {
        console.log("newww", res);
        setNewProducts(res.data["pageData"]);
      })
      .catch((err) => console.log(err));
  };

  const handlePaginationServicesClick = (e) => {
    let pageNum = e.selected;
    httpService
      .post(`${process.env.REACT_APP_SERVER_URL}service/listpage`, {
        pageSize: servicesSize,
        pageNumber: pageNum,
      })
      .then((res) => {
        console.log("newww", res);
        setServices(res.data["pageData"]);
      })
      .catch((err) => console.log(err));
  };

  const openServiceForm = (service) => {
    if (localStorage.getItem("token")) {
      history.push({
        pathname: "/servicerequest",
        state: { service: service },
      });
      window.scrollTo(0, 0);
    } else {
      serviceNotValid();
    }
  };

  return (
    <div className='container-fluid'>
      <ToastContainer
        progressClassName='toastProgress'
        bodyClassName='toastBody'
        toastClassName='toastContainer'
      />

      <div className='row mb-5 mt-0'>
        <div
          id='carouselExampleControls'
          className='carousel slide '
          data-ride='carousel'
        >
          <ol className='carousel-indicators'>
            <li
              data-target='#carouselExampleIndicators'
              data-slide-to='0'
              className='active'
            ></li>
            <li data-target='#carouselExampleIndicators' data-slide-to='1'></li>
            <li data-target='#carouselExampleIndicators' data-slide-to='2'></li>
          </ol>
          <div className='carousel-inner'>
            <div className='carousel-item active'>
              <img
                className='img-carousle'
                src='/assets/slider-1.png'
                alt='First slide'
              />
            </div>
            <div className='carousel-item'>
              <img
                className='img-carousle'
                src='/assets/slider-2.png'
                alt='...'
              />
            </div>
            <div className='carousel-item'>
              <img
                className='img-carousle'
                src='/assets/slider-3.png'
                alt='...'
              />
            </div>
          </div>
          <a
            className='carousel-control-prev'
            href='#carouselExampleControls'
            role='button'
            data-slide='prev'
          >
            <span
              className='carousel-control-prev-icon'
              aria-hidden='true'
            ></span>
            <span className='sr-only'>Previous</span>
          </a>
          <a
            className='carousel-control-next'
            href='#carouselExampleControls'
            role='button'
            data-slide='next'
          >
            <span
              className='carousel-control-next-icon'
              aria-hidden='true'
            ></span>
            <span className='sr-only'>Next</span>
          </a>
        </div>
      </div>

      <div className='row mt-5'>
        <div className='col-md-2'></div>
        <div className='col-md-8 text-center'>
          <h5 style={{ fontWeight: "bold" }}>{i18n.t("welcomeMagd")}</h5>
          <p className='m-2'>{i18n.t("historyDesc")}</p>
        </div>
        <div className='col-md-2'></div>
      </div>
      {loader ? (
        <div className='text-center'>
          <div className='spinner-border text-primary' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      ) : (
        <div className='row justify-content-center m-5'>
          {categories.map((c, i) => (
            <>
              {c.isParent ? (
                <div
                  key={i}
                  className='col-md-2 m-2 text-center btnScale'
                  style={{ border: "1px solid #E5E5E5" }}
                  onClick={() => openCategoryDetails(c)}
                >
                  <div className='w-100 h-100'>
                    <img
                      src={c.mainImage !== null ? c.mainImage.path : null}
                      alt=''
                      height='70%'
                      width='100%'
                    />
                    <div
                      style={{
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      <span className='card-title'>{c.name}</span>
                    </div>
                  </div>
                </div>
              ) : null}
            </>
          ))}
        </div>
      )}
      {bestSellers.length === 0 ? null : (
        <>
          <div className='banner-container'>
            <img alt='' src='/assets/banner.png' width='100%' height='100%' />
          </div>
          <div className='row m-5'>
            <p
              className='col-md-8'
              style={{ fontWeight: "bolder", fontSize: "22px" }}
            >
              {i18n.t("bestSellers")}
            </p>
            <div className='col-md-4'>
              <ReactPaginate
                previousLabel={i18n.t("<")}
                nextLabel={i18n.t(">")}
                pageCount={Math.ceil(totalSellerCount / pageSellerSize)}
                onPageChange={(e) => handlePaginationSellerClick(e)}
                containerClassName={"pagination"}
                activeClassName={"active"}
                pageLinkClassName='hide-pagination'
              />
            </div>
          </div>

          <div className='row m-5'>
            <div className='col-md-12'>
              <div className='row justify-content-center'>
                {bestSellers.map((s, i) => (
                  <div
                    key={i}
                    className='col-md-2 mr-5 mt-1 btnScale'
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      borderRadius: 10,
                      border: "1px solid #E5E5E5",
                    }}
                    onClick={() => openProductDetails(s.id)}
                  >
                    <img
                      src={s.mainImage !== null ? s.mainImage.path : null}
                      alt=''
                      height='70%'
                      width='100%'
                    />
                    <div
                      style={{
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      <p className='card-title'>{s.name}</p>
                      <p style={{ color: "darkred" }}>
                        {s.price} {i18n.t("EGP")}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}

      <div className='banner-container'>
        <img alt='' src='/assets/banner.png' width='100%' height='80%' />
      </div>

      <div className='row m-5'>
        <p
          className='col-md-8'
          style={{ fontWeight: "bolder", fontSize: "22px" }}
        >
          {i18n.t("newProducts")}
        </p>
        <div className='col-md-4'>
          <ReactPaginate
            previousLabel={i18n.t("<")}
            nextLabel={i18n.t(">")}
            pageCount={Math.ceil(totalNewCount / pageNewSize)}
            onPageChange={(e) => handlePaginationNewClick(e)}
            containerClassName={"pagination"}
            activeClassName={"active"}
            pageLinkClassName='hide-pagination'
          />
        </div>
      </div>
      <div className='row m-5'>
        <div className='col-md-12'>
          <div className='row justify-content-center'>
            {newProducts.map((n, i) => (
              <div
                key={i}
                className='col-md-2 mr-5 mt-1 btnScale'
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  borderRadius: 10,
                  border: "1px solid #E5E5E5",
                }}
                onClick={() => openProductDetails(n.id)}
              >
                <img
                  src={n.mainImage !== null ? n.mainImage.path : null}
                  alt=''
                  height='70%'
                  width='100%'
                />
                <div
                  style={{
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  <p className='card-title'>{n.name}</p>
                  <p style={{ color: "darkred" }}>
                    {n.price} {i18n.t("EGP")}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='banner-container'>
        <img alt='' src='/assets/banner.png' width='100%' height='80%' />
      </div>
      <div className='row m-5'>
        <p
          className='col-md-8'
          style={{ fontWeight: "bolder", fontSize: "22px" }}
        >
          {i18n.t("ourServices")}
        </p>
        <div className='col-md-4'>
          <ReactPaginate
            previousLabel={i18n.t("<")}
            nextLabel={i18n.t(">")}
            pageCount={Math.ceil(totalServicesCount / servicesSize)}
            onPageChange={(e) => handlePaginationServicesClick(e)}
            containerClassName={"pagination"}
            activeClassName={"active"}
            pageLinkClassName='hide-pagination'
          />
        </div>
      </div>
      <div className='row m-5'>
        <div className='col-md-12'>
          <div className='row justify-content-center'>
            {services.map((s, i) => (
              <div
                key={i}
                className='col-md-2 mr-5 mt-1'
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  borderRadius: 10,
                  border: "1px solid #E5E5E5",
                }}
              >
                <div
                  style={{
                    color: "black",
                  }}
                >
                  <p
                    className='text-center'
                    style={{ fontWeight: "bold", color: "#2F5FA0" }}
                  >
                    {s.name}
                  </p>
                  <p className='text-center'>{s.description}</p>
                  <button
                    onClick={() => openServiceForm(s)}
                    className='request-btn mt-3 mb-3 btnClick'
                  >
                    {i18n.t("requestService")}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

import React, { useEffect, useState } from "react";
import SideBar from "../shared/sideBar/sideBar";
import { useHistory } from "react-router-dom";
import "./categoryList.css";
import AOS from "aos";
import "aos/dist/aos.css";
import httpService from "../../services/httpService";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";

const CategoryList = (props) => {
  const { t, i18n } = useTranslation();

  const [change, setChange] = useState(0);

  const [priceFrom, setPriceFrom] = useState();
  const [priceTo, setPriceTo] = useState();

  const [brandFilter, setBrandFilter] = useState([]);
  const [propertyFilter, setPropertyFilter] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState([]);

  const location = useLocation();
  const history = useHistory();
  const [categoryList, setCategoryList] = useState([]);
  const [categoryListLength, setCategoryListLength] = useState(0);

  const [pageSize, setPageSize] = useState(16);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);

  const loginFirst = () => toast(i18n.t("loginFirst"));
  const addedSuccessfully = () => toast(i18n.t("addedSuccessfully"));
  const alreadyAddedToCart = () => toast(i18n.t("alreadyAddedToCart"));
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    AOS.init({
      duration: 2200,
    });
    httpService
      .post(`${process.env.REACT_APP_SERVER_URL}product/listpage`, {
        searchText: location.state.search ? location.state.search : null,
        pageSize: pageSize,
        pageNumber: pageNumber,
        filter: {
          categoryId:
            categoryFilter.length !== 0
              ? categoryFilter
              : location.state.categoryId !== undefined
              ? [location.state.categoryId]
              : null,
          brandId:
            brandFilter.length !== 0
              ? brandFilter
              : location.state.brandId !== undefined
              ? [location.state.brandId]
              : null,
          propertyFilter: propertyFilter.length !== 0 ? propertyFilter : null,
          priceFrom:
            priceFrom !== null
              ? priceFrom
              : location.state.priceFrom
              ? location.state.priceFrom
              : null,
          priceTo:
            priceTo !== null
              ? priceTo
              : location.state.priceTo
              ? location.state.priceTo
              : null,
        },
      })
      .then((res) => {
        setCategoryList(res.data.pageData);
        setCategoryListLength(res.data.totalCount);
        setTotalCount(res.data.totalCount);
        setLoader(false);
      })
      .catch((err) => console.log(err));
  }, [
    priceFrom,
    priceTo,
    brandFilter,
    propertyFilter,
    categoryFilter,
    location.state.search,
    change,
    location.state.brandId,
    location.state.categoryId,
    location.state.propertyFilter,
  ]);

  const addToCart = (productId) => {
    if (localStorage.getItem("token")) {
      httpService
        .post(`${process.env.REACT_APP_SERVER_URL}UserSavedProducts/insert`, {
          productId: productId,
          quantity: 1,
          saveTypeEnum: 1,
        })
        .then((res) => {
          setChange(change + 1);
          addedSuccessfully();
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            alreadyAddedToCart();
          } else {
            console.log(err);
          }
        });
    } else {
      loginFirst();
    }
  };

  const openProductDetails = (productId) => {
    history.push({
      pathname: "/productdetails",
      state: { productId: productId },
    });
  };

  const changePropertyFilterChild = (propertiesArr) => {
    setPropertyFilter(propertiesArr);
  };

  const changeBrandFilterChild = (brandsArr) => {
    setBrandFilter(brandsArr);
  };

  const changeCategoryFilterChild = (categoriesArr) => {
    setCategoryFilter(categoriesArr);
  };

  const changePriceFromFilter = (from, to) => {
    setPriceFrom(from);
    setPriceTo(to);
  };

  const handlePaginationClick = (e) => {
    let pageNum = e.selected;
    httpService
      .post(`${process.env.REACT_APP_SERVER_URL}product/listpage`, {
        searchText: location.state.search ? location.state.search : null,
        pageSize: pageSize,
        pageNumber: pageNum,
        filter: {
          categoryId:
            categoryFilter.length !== 0
              ? categoryFilter
              : location.state.categoryId !== undefined
              ? [location.state.categoryId]
              : null,
          brandId:
            brandFilter.length !== 0
              ? brandFilter
              : location.state.brandId !== undefined
              ? [location.state.brandId]
              : null,
          propertyFilter:
            propertyFilter.length !== 0
              ? propertyFilter
              : location.state.propertyFilter !== undefined
              ? [location.state.propertyFilter]
              : null,
          priceFrom:
            priceFrom !== null
              ? priceFrom
              : location.state.priceFrom
              ? location.state.priceFrom
              : null,
          priceTo:
            priceTo !== null
              ? priceTo
              : location.state.priceTo
              ? location.state.priceTo
              : null,
        },
      })
      .then((res) => {
        setCategoryList(res.data.pageData);
        setTotalCount(res.data.totalCount);
        setPageNumber(pageNumber + 1);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className='container-fluid'>
      <ToastContainer
        progressClassName='toastProgress'
        bodyClassName='toastBody'
        toastClassName='toastContainer'
      />

      <div className='row mt-5 mb-5'>
        <div className='col-md-2' data-aos='fade-right'>
          <SideBar
            changePriceFilter={(from, to) => changePriceFromFilter(from, to)}
            changeBrandFilter={(brands) => changeBrandFilterChild(brands)}
            changePropertyFilter={(properties) =>
              changePropertyFilterChild(properties)
            }
            changeCategoryFilter={(categories) =>
              changeCategoryFilterChild(categories)
            }
          />
        </div>
        <div className='col-md-10'>
          <div
            data-aos='fade-down'
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <h4>
              {i18n.t("all-in-one")} {location.state.categoryName}{" "}
              {location.state.brandName} ({categoryListLength})
            </h4>
          </div>
          {loader ? (
            <div className='text-center'>
              <div className='spinner-border text-primary' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            </div>
          ) : (
            <div className='row mb-5'>
              {categoryList.length !== 0 ? (
                <>
                  {categoryList.map((c, i) => (
                    <div key={i} className='col-md-3 mt-5' data-aos='fade-up'>
                      <div
                        className='card text-center btnScale'
                        style={{ width: "18rem", minHeight: "60vh" }}
                      >
                        <img
                          alt=''
                          className='card-img-top'
                          src={c.mainImage !== null ? c.mainImage.path : ""}
                          style={{ height: "40vh" }}
                          onClick={() => {
                            openProductDetails(c.id);
                          }}
                        />
                        <div className='card-body'>
                          <h5
                            className='card-title'
                            onClick={() => {
                              openProductDetails(c.id);
                            }}
                          >
                            {c.name}
                          </h5>

                          <h5
                            onClick={() => {
                              openProductDetails(c.id);
                            }}
                            style={{ color: "darkred" }}
                          >
                            {c.price - c.discountAmount} {i18n.t("EGP")}
                          </h5>
                          {c.hasDiscount ? (
                            <p
                              onClick={() => {
                                openProductDetails(c.id);
                              }}
                              style={{
                                color: "darkred",
                                position: "absolute",
                                bottom: "35px",
                              }}
                            >
                              {i18n.t("was")} {c.price} {i18n.t("EGP")} |{" "}
                              {i18n.t("saves")} {c.discountAmount}{" "}
                              {i18n.t("EGP")}
                            </p>
                          ) : null}
                          <div
                            style={{
                              position: "absolute",
                              bottom: "10px",
                              left: "50px",
                            }}
                          >
                            <button
                              onClick={() => addToCart(c.id)}
                              className='add-cart-btn pr-5 pl-5 pt-2 pb-2 btnClickWhite'
                            >
                              {i18n.t("addToCart")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className='row m-5 text-center'>
                  <img
                    alt=''
                    src='/assets/nodata.svg'
                    width='60%'
                    height='50%'
                  />
                  <p className='empty-list'>{i18n.t("emptyFilter")}</p>
                </div>
              )}
            </div>
          )}

          {categoryList.length !== 0 ? (
            <div className='row mt-5 mb-5'>
              <div className='col-md-8'></div>
              <div className='col-md-4'>
                <ReactPaginate
                  previousLabel={i18n.t("prev")}
                  nextLabel={i18n.t("next")}
                  pageCount={Math.ceil(totalCount / pageSize)}
                  onPageChange={(e) => handlePaginationClick(e)}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CategoryList;

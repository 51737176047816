import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import httpService from "../../../services/httpService";
import { useHistory } from "react-router-dom";
import CategoryTree from "../../categoryTree/categoryTree";

const SideBar = (props) => {
  const { t, i18n } = useTranslation();
  const [brands, setBrands] = useState([]);
  const [properties, setProperties] = useState([]);
  const [categories, setCategories] = useState([]);
  const [prices, setPrices] = useState([]);

  const [brandsFilter, setBrandsFilter] = useState([]);
  const [propertiesFilter, setPropertiesFilter] = useState([]);
  const [categoriesFilter, setCategoriesFilter] = useState([]);

  const history = useHistory();

  useEffect(() => {
    httpService
      .post(`${process.env.REACT_APP_SERVER_URL}Properties/listpage`, {
        searchText: "",
        pageSize: 999,
        pageNumber: 0,
      })
      .then((res) => {
        setProperties(res.data.pageData);
      })
      .catch((err) => console.log(err));

    httpService
      .get(`${process.env.REACT_APP_SERVER_URL}brand/list`)
      .then((res) => {
        setBrands(res.data);
      })
      .catch((err) => console.log(err));

    httpService
      .get(`${process.env.REACT_APP_SERVER_URL}category/list`)
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => console.log(err));

    httpService
      .post(`${process.env.REACT_APP_SERVER_URL}product/listpage`, {
        searchText: "",
        pageSize: 999,
        filter: {
          categoryId: null,
          brandId: null,
          priceFrom: null,
          priceTo: null,
        },
      })
      .then((res) => {
        setPrices(res.data.ranges);
      })
      .catch((err) => console.log(err));
  }, [props.changeCategoryFilter]);

  const propertyFilter = (propertyId, checked) => {
    let propertyArr = [...propertiesFilter];
    if (checked) {
      propertyArr.push(propertyId);
      setPropertiesFilter(propertyArr);
      props.changePropertyFilter(propertyArr);
    } else {
      const index = propertyArr.indexOf(propertyId);
      if (index > -1) {
        propertyArr.splice(index, 1);
        setPropertiesFilter(propertyArr);
        props.changePropertyFilter(propertyArr);
      }
    }
    history.push({
      pathname: "/category",
      state: {
        propertyFilter: propertyArr,
      },
    });
  };

  const brandFilter = (brandId, checked) => {
    let brandArr = [...brandsFilter];
    if (checked) {
      brandArr.push(brandId);
      setBrandsFilter(brandArr);
      props.changeBrandFilter(brandArr);
    } else {
      const index = brandArr.indexOf(brandId);
      if (index > -1) {
        brandArr.splice(index, 1);
        setBrandsFilter(brandArr);
        props.changeBrandFilter(brandArr);
      }
    }
    history.push({
      pathname: "/category",
      state: {
        brandId: brandArr,
      },
    });
  };

  const categoryFilter = (categoryId, checked) => {
    let catArr = [...categoriesFilter];
    if (checked) {
      catArr.push(categoryId);
      setCategoriesFilter(catArr);
      props.changeCategoryFilter(catArr);
    } else {
      const index = catArr.indexOf(categoryId);
      if (index > -1) {
        catArr.splice(index, 1);
        setCategoriesFilter(catArr);
        props.changeCategoryFilter(catArr);
      }
    }
    history.push({
      pathname: "/category",
      state: {
        categoryId: catArr,
      },
    });
  };

  const priceFilter = (priceFrom, priceTo) => {
    props.changePriceFilter(priceFrom, priceTo);
    history.push({
      pathname: "/category",
      state: {
        priceFrom: priceFrom,
        priceTo: priceTo,
      },
    });
  };

  const priceCustomFilter = () => {
    props.changePriceFilter(null, null);
    history.push({
      pathname: "/category",
      state: {
        priceFrom: null,
        priceTo: null,
      },
    });
  };

  return (
    <div className='container'>
      <div className='row mb-3'>
        <h5>{i18n.t("category")}</h5>
      </div>

      <CategoryTree
        changeCategoryFilterTree={(categories) =>
          props.changeCategoryFilter(categories)
        }
      />
      {/* {categories.map((c, i) => (
        <div key={i} style={{ display: "flex", flexDirection: "row" }}>
          <input
            className='form-check-input'
            type='checkbox'
            id='flexCheckDefault'
            onChange={(e) => categoryFilter(c.id, e.target.checked)}
          />
          <p className='ml-4 mr-4'>{c.name}</p>
        </div>
      ))} */}

      {properties.map((p, i) => (
        <div className='mb-3 mt-5'>
          {p.values.length > 0 ? (
            <p style={{ fontWeight: "bold" }}>{p.name}</p>
          ) : null}
          {p.values.map((v) => (
            <div key={i} style={{ display: "flex", flexDirection: "row" }}>
              <input
                className='form-check-input'
                type='checkbox'
                id='flexCheckDefault'
                onChange={(e) => propertyFilter(v.id, e.target.checked)}
              />
              <p className='ml-4 mr-4'>{v.name}</p>
            </div>
          ))}
        </div>
      ))}

      <div className='row mb-3 mt-5'>
        <h5>{i18n.t("brand")}</h5>
      </div>
      {brands.map((b, i) => (
        <div key={i} style={{ display: "flex", flexDirection: "row" }}>
          <input
            className='form-check-input'
            type='checkbox'
            id='flexCheckDefault'
            onChange={(e) => brandFilter(b.id, e.target.checked)}
          />
          <p className='ml-4 mr-4'>{b.text}</p>
        </div>
      ))}

      <div className='row mb-3 mt-5'>
        <h5>{i18n.t("price")}</h5>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <input
          className='form-check-input'
          type='radio'
          name='price'
          onChange={() => priceCustomFilter()}
          id='flexCheckDefault'
        />
        <p className='ml-4 mr-4'>{i18n.t("custom")}</p>
      </div>
      {prices.map((p, i) => (
        <div key={i} style={{ display: "flex", flexDirection: "row" }}>
          <input
            className='form-check-input'
            type='radio'
            name='price'
            onChange={() => priceFilter(p.Key, p.Value)}
            id='flexCheckDefault'
          />
          <p className='ml-4 mr-4'>{Math.floor(p.Key)}</p>-
          <p className='ml-4 mr-4'>{Math.floor(p.Value)}</p> {i18n.t("EGP")}
        </div>
      ))}
    </div>
  );
};

export default SideBar;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import "./profile.css";
import jwt_decode from "jwt-decode";
import httpService from "../../services/httpService";
import "aos/dist/aos.css";
import AOS from "aos";

const Profile = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [userEmail, setUserEmail] = useState("");
  const [userData, setUserData] = useState();
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    AOS.init({
      duration: 2200,
    });
    if (localStorage.getItem("token")) {
      let decoded = jwt_decode(localStorage.getItem("token"));
      setUserEmail(
        decoded[
          "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"
        ]
      );
    }
    httpService
      .get(
        `${process.env.REACT_APP_SERVER_URL}Authentication/profile/?email=${userEmail}`
      )
      .then((res) => {
        setUserData(res.data);
        setLoader(false);
      })
      .catch((err) => console.log(err));
  }, [userEmail]);

  return (
    <div className='container mt-5 mb-5' data-aos='zoom-out'>
      {loader ? (
        <div className='text-center mt-5 mb-5'>
          <div className='spinner-border text-primary' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      ) : (
        <>
          {userData !== null && userData !== undefined ? (
            <>
              <div className='row mb-3'>
                <div className='col-md-6' style={{ fontWeight: "bold" }}>
                  <p>{i18n.t("fstName")}</p>
                </div>
                <div className='col-md-6' style={{ color: "#2F5FA0" }}>
                  {userData.firstName !== null &&
                  userData.firstName !== undefined
                    ? userData.firstName
                    : null}
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-md-6' style={{ fontWeight: "bold" }}>
                  <p>{i18n.t("lstName")}</p>
                </div>
                <div className='col-md-6' style={{ color: "#2F5FA0" }}>
                  {userData.lastName !== null && userData.lastName !== undefined
                    ? userData.lastName
                    : null}
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-md-6' style={{ fontWeight: "bold" }}>
                  <p> {i18n.t("email")}</p>
                </div>
                <div className='col-md-6' style={{ color: "#2F5FA0" }}>
                  {userData.userEmail !== null &&
                  userData.userEmail !== undefined
                    ? userData.userEmail
                    : null}
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-md-6' style={{ fontWeight: "bold" }}>
                  <p>{i18n.t("cart")}</p>
                </div>
                <div className='col-md-6'>
                  <h6
                    onClick={() => history.push("/cart")}
                    style={{ color: "#2F5FA0" }}
                    className='btnHoverUnderLine'
                  >
                    {i18n.t("openCart")}
                  </h6>
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-md-6' style={{ fontWeight: "bold" }}>
                  <p>{i18n.t("wish")}</p>
                </div>
                <div className='col-md-6'>
                  <h6
                    onClick={() => history.push("/wish")}
                    style={{ color: "#2F5FA0" }}
                    className='btnHoverUnderLine'
                  >
                    {i18n.t("openWish")}
                  </h6>
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-md-6' style={{ fontWeight: "bold" }}>
                  <p>{i18n.t("myOrders")}</p>
                </div>
                <div className='col-md-6'>
                  <h6
                    onClick={() => history.push("/orders")}
                    style={{ color: "#2F5FA0" }}
                    className='btnHoverUnderLine'
                  >
                    {i18n.t("openRequests")}
                  </h6>
                </div>
              </div>
              <div className='row'>
                <button
                  onClick={() => history.push("/")}
                  className='continue-action'
                >
                  <span style={{ color: "#2F5FA0" }}>
                    {i18n.t("continueShop")}
                  </span>
                </button>
              </div>
            </>
          ) : (
            <p className='mt-5 mb-5'>{i18n.t("somethingWrong")}</p>
          )}
        </>
      )}
    </div>
  );
};

export default Profile;

import React, { useContext, useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import "./serviceRequest.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import httpService from "../../services/httpService";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ServiceRequest = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const [loader, setLoader] = useState(false);

  const enterFormValues = () => toast(i18n.t("formValidation"));
  const serviceRequestSubmitted = () => toast(i18n.t("serviceRequested"));
  const serviceAlreadyRequested = () =>
    toast(i18n.t("serviceAlreadyRequested"));

  let files = [];
  let formData = new FormData();

  useEffect(() => {
    AOS.init({
      duration: 2200,
    });
  }, []);

  const handleFileSelected = (e) => {
    files = Array.from(e.target.files);
    formData.append("file", files[0]);
    setSelectedFile(files[0]);
  };

  const requestServiceSubmit = () => {
    setLoader(true);
    if (
      location.state.service.englishName === "Maintenance" &&
      selectedFile !== null &&
      mobile !== "" &&
      address !== ""
    ) {
      serviceRequest();
    } else if (
      location.state.service.englishName !== "Maintenance" &&
      mobile !== "" &&
      address !== ""
    ) {
      serviceRequest();
    } else {
      enterFormValues();
      setLoader(false);
    }
  };

  const serviceRequest = () => {
    let formObject = {
      serviceId: location.state.service.id,
      PhoneNumber: mobile,
      address: address,
    };
    formData.append("data", JSON.stringify(formObject));
    httpService
      .post(`${process.env.REACT_APP_SERVER_URL}userservices/insert`, formData)
      .then((res) => {
        if (res.data.code === -4) {
          serviceAlreadyRequested();
          setLoader(false);
        } else {
          serviceRequestSubmitted();
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  return (
    <div className='container '>
      <ToastContainer
        progressClassName='toastProgress'
        bodyClassName='toastBody'
        toastClassName='toastContainer'
      />
      <div className='row mt-5' data-aos='fade-down'>
        <h3>{i18n.t("requestServiceTitle")}</h3>
      </div>

      <div className='row mt-5 mb-5'>
        <div className='col-md-6'>
          <p style={{ fontWeight: "bold", fontSize: "18px" }}>
            {i18n.t("serviceName")}
          </p>
        </div>
        {location.state !== undefined ? (
          <div className='col-md-6'>
            <p>{location.state.service.name}</p>
          </div>
        ) : (
          history.push("/servicelist")
        )}
      </div>
      <div className='row mb-5'>
        <div className='col-md-6'>
          <p style={{ fontWeight: "bold", fontSize: "18px" }}>
            {i18n.t("serviceDesc")}
          </p>
        </div>
        <div className='col-md-6'>
          {location.state !== undefined ? (
            <p>{location.state.service.description}</p>
          ) : (
            history.push("/servicelist")
          )}
        </div>
      </div>
      {location.state.service.englishName === "Maintenance" ? (
        <div className='row mb-5'>
          <div className='col-md-6'>
            <p style={{ fontWeight: "bold", fontSize: "18px" }}>
              {i18n.t("uploadInvoice")}
            </p>
          </div>
          <div className='col-md-6'>
            <input
              onChange={(e) => handleFileSelected(e)}
              type='file'
              accept='image/png, image/gif, image/jpeg'
              multiple='multiple'
            />
          </div>
        </div>
      ) : null}

      <div className='row mb-5'>
        <div className='col-md-6'>
          <p style={{ fontWeight: "bold", fontSize: "18px" }}>
            {i18n.t("mobile")}
          </p>
        </div>
        <div className='col-md-6'>
          <input
            className='form-control'
            onChange={(e) => setMobile(e.target.value)}
            type='text'
          />
        </div>
      </div>
      <div className='row mb-5'>
        <div className='col-md-6'>
          <p style={{ fontWeight: "bold", fontSize: "18px" }}>
            {i18n.t("address")}
          </p>
        </div>
        <div className='col-md-6'>
          <input
            className='form-control'
            onChange={(e) => setAddress(e.target.value)}
            type='text'
          />
        </div>
      </div>
      <div className='row mb-5'>
        <div className='col-md-6'></div>
        <div className='col-md-6'>
          {loader ? (
            <div className='text-center mt-5 mb-5'>
              <div className='spinner-border text-primary' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            </div>
          ) : (
            <button
              onClick={requestServiceSubmit}
              className='request-btn m-3 w-50 btnClick'
            >
              {i18n.t("sendInvoice")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ServiceRequest;

import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useHistory } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import httpService from "../../services/httpService";
import { useLocation } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import jwt_decode from "jwt-decode";
import "./productDetails.css";
import { useTranslation } from "react-i18next";

const blockInvalidChar = (e) =>
  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

const ProductDetails = (props) => {
  const { t, i18n } = useTranslation();

  const location = useLocation();
  const history = useHistory();
  const [change, setChange] = useState(0);

  const [productData, setProductData] = useState({});
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [relatedByCategory, setRelatedByCategory] = useState([]);
  const [ratingList, setRatingList] = useState([]);
  const [avgRate, setAvgRate] = useState();
  const [itemQuantityCart, setItemQuantityCart] = useState("");
  const [comment, setComment] = useState("");
  const [rateValue, setRateValue] = useState();
  const [token, setToken] = useState("");

  const [pageRelatedPRDSize, setPageRelatedPRDSize] = useState(4);
  const [totalRelatedPRDCount, setTotalRelatedPRDCount] = useState(0);
  const [pageRelatedPRDNumber, setPageRelatedPRDNumber] = useState(0);

  const [pageRelatedCATSize, setPageRelatedCATSize] = useState(4);
  const [totalRelatedCATCount, setTotalRelatedCATCount] = useState(0);
  const [pageRelatedCATNumber, setPageRelatedCATNumber] = useState(0);

  const loginFirst = () => toast(i18n.t("loginFirst"));
  const addQuantityFirst = () => toast(i18n.t("addQuantity"));
  const addedSuccessfully = () => toast(i18n.t("productCartAdded"));
  const alreadyAddedToCart = () => toast(i18n.t("alreadyAddedToCart"));
  const rateSuccessfullyAddedd = () => toast(i18n.t("rateSuccessfullyAddedd"));
  const quantityNotZero = () => toast(i18n.t("quantityNotZero"));

  useEffect(() => {
    AOS.init({
      duration: 2200,
    });
    if (localStorage.getItem("token")) {
      let decoded = jwt_decode(localStorage.getItem("token"));
      setToken(decoded);
    } else {
      setToken("");
    }
    httpService
      .get(
        `${process.env.REACT_APP_SERVER_URL}product/details/${location.state.productId}`
      )
      .then((res) => {
        setProductData(res.data);
        httpService
          .post(`${process.env.REACT_APP_SERVER_URL}rate/listpage`, {
            searchText: "",
            pageSize: 999,
            isPrint: true,
            filter: {
              objectId: res.data.id,
              objectType: "Product",
            },
          })
          .then((res) => {
            setRatingList(res.data.pageData);
            let avg = 0;
            res.data.pageData.forEach((p) => (avg += p.rateValue));
            let average = avg / res.data.pageData.length;
            setAvgRate(average);
          })
          .catch((err) => console.log(err));
        httpService
          .post(`${process.env.REACT_APP_SERVER_URL}product/listpage`, {
            searchText: "",
            pageSize: pageRelatedCATSize,
            pageNumber: 0,
            sortDirection: 0,
            filter: {
              categoryId: [res.data.category.id],
            },
          })
          .then((res) => {
            setTotalRelatedCATCount(res.data.totalCount);
            setRelatedByCategory(res.data.pageData);
          })
          .catch((err) => console.log(err));
        httpService
          .post(`${process.env.REACT_APP_SERVER_URL}product/listpage`, {
            searchText: "",
            pageSize: pageRelatedPRDSize,
            pageNumber: 0,
            sortDirection: 0,
            filter: {
              brandId: [res.data.brand.id],
            },
          })
          .then((res) => {
            setTotalRelatedPRDCount(res.data.totalCount);
            setRelatedProducts(res.data.pageData);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }, [change]);

  const handlePaginationRelatedPRDClick = (e) => {
    let pageNum = e.selected;
    httpService
      .get(
        `${process.env.REACT_APP_SERVER_URL}product/details/${location.state.productId}`
      )
      .then((res) => {
        setProductData(res.data);
        httpService
          .post(`${process.env.REACT_APP_SERVER_URL}product/listpage`, {
            searchText: "",
            pageSize: pageRelatedPRDSize,
            pageNumber: pageNum,
            sortDirection: 0,
            filter: {
              brandId: [res.data.brand.id],
            },
          })
          .then((res) => {
            setRelatedProducts(res.data.pageData);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  const handlePaginationRelatedCATClick = (e) => {
    let pageNum = e.selected;
    httpService
      .get(
        `${process.env.REACT_APP_SERVER_URL}product/details/${location.state.productId}`
      )
      .then((res) => {
        setProductData(res.data);
        httpService
          .post(`${process.env.REACT_APP_SERVER_URL}product/listpage`, {
            searchText: "",
            pageSize: pageRelatedCATSize,
            pageNumber: pageNum,
            filter: {
              categoryId: [res.data.category.id],
            },
          })
          .then((res) => {
            setRelatedByCategory(res.data.pageData);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  const ratingChanged = (newRating) => {
    setRateValue(newRating);
  };

  const sendRate = (productId) => {
    httpService
      .post(`${process.env.REACT_APP_SERVER_URL}rate/insert`, {
        userName:
          token !== ""
            ? token[
                "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
              ]
            : "",
        rateValue: rateValue,
        comment: comment,
        isVerifiedOrder: true,
        creationDate: new Date(),
        objectId: productId,
        objectType: "Product",
      })
      .then((res) => {
        setChange(change + 1);
        rateSuccessfullyAddedd();
      })
      .catch((err) => console.log(err));
  };

  const addToCart = (productId) => {
    if (localStorage.getItem("token")) {
      if (itemQuantityCart !== "") {
        httpService
          .post(`${process.env.REACT_APP_SERVER_URL}UserSavedProducts/insert`, {
            productId: productId,
            quantity: Number(itemQuantityCart),
            saveTypeEnum: 1,
          })
          .then((res) => {
            setChange(change + 1);
            addedSuccessfully();
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          })
          .catch((err) => {
            if (err.response.status === 403) {
              alreadyAddedToCart();
            } else {
              console.log(err);
            }
          });
      } else {
        addQuantityFirst();
      }
    } else {
      loginFirst();
    }
  };

  const openProductDetails = (product) => {
    httpService
      .get(`${process.env.REACT_APP_SERVER_URL}product/details/${product.id}`)
      .then((res) => {
        setProductData(res.data);
        httpService
          .post(`${process.env.REACT_APP_SERVER_URL}rate/listpage`, {
            searchText: "",
            pageSize: 999,
            isPrint: true,
            filter: {
              objectId: res.data.id,
              objectType: "Product",
            },
          })
          .then((res) => {
            setRatingList(res.data.pageData);
            let avg = 0;
            res.data.pageData.forEach((p) => (avg += p.rateValue));
            let average = avg / res.data.pageData.length;
            setAvgRate(average);
          })
          .catch((err) => console.log(err));
        httpService
          .post(`${process.env.REACT_APP_SERVER_URL}product/listpage`, {
            searchText: "",
            pageSize: pageRelatedCATSize,
            pageNumber: 0,
            sortDirection: 0,
            filter: {
              categoryId: [res.data.category.id],
            },
          })
          .then((res) => {
            setRelatedByCategory(res.data.pageData);
          })
          .catch((err) => console.log(err));
        httpService
          .post(`${process.env.REACT_APP_SERVER_URL}product/listpage`, {
            searchText: "",
            pageSize: pageRelatedPRDSize,
            pageNumber: 0,
            sortDirection: 0,
            filter: {
              brandId: [res.data.brand.id],
            },
          })
          .then((res) => {
            setRelatedProducts(res.data.pageData);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
    window.scrollTo(0, 0);
  };
  return (
    <div className='container'>
      <ToastContainer
        progressClassName='toastProgress'
        bodyClassName='toastBody'
        toastClassName='toastContainer'
      />

      <div className='row mt-5 mb-5'>
        <div className='col-md-6 text-center' data-aos='fade-left'>
          {productData.attachments && productData.mainImage !== null ? (
            <Carousel>
              <div>
                <img
                  alt=''
                  src={productData.mainImage.path}
                  width='50%'
                  height='50%'
                />
              </div>
              {productData.attachments.map((s, i) => (
                <div key={i}>
                  {s.path !== null ? (
                    <img alt='' src={s.path} width='100%' height='100%' />
                  ) : (
                    <></>
                  )}
                </div>
              ))}
            </Carousel>
          ) : null}
        </div>
        <div className='col-md-6 ' data-aos='fade-right'>
          <div
            className='mb-3'
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {productData.brand ? <h5>{productData["brand"]["name"]}</h5> : null}
          </div>
          <p>{productData.name}</p>
          {productData.productCurrentColor ? (
            <div>
              <p>{productData.productCurrentColor.color.name}</p>
              {productData.productCurrentColor.availableQuantity !==
              1 ? null : (
                <p>
                  ({productData.productCurrentColor.availableQuantity})
                  {i18n.t("items")}
                </p>
              )}
            </div>
          ) : null}

          {productData.hasDiscount ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                color: "darkred",
              }}
            >
              <h5>
                {productData.price - productData.discountAmount} {i18n.t("EGP")}{" "}
                ({i18n.t("saves")} {productData.discountAmount} {i18n.t("EGP")})
              </h5>
              <p>
                {i18n.t("was")} {productData.price} {i18n.t("EGP")} (
                {productData.dicountPercentage}
                {i18n.t("off")})
              </p>
            </div>
          ) : (
            <h5 style={{ color: "darkred" }}>
              {productData.price} {i18n.t("EGP")}
            </h5>
          )}
          <div className='row mt-3'>
            <div className='col-md-6'>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <img
                  alt=''
                  src='/assets/VAT.png'
                  width='20'
                  height='20'
                  className='mr-2'
                />
                <p>{i18n.t("includeVat")}</p>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <img
                  alt=''
                  src='/assets/Warranty.png'
                  width='20'
                  height='20'
                  className='mr-2'
                />
                <p>
                  {productData.warranty} {i18n.t("warranty")}
                </p>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <img
                  alt=''
                  src='/assets/Rating.png'
                  width='20'
                  height='20'
                  className='mr-2'
                />
                <p>
                  {i18n.t("overRating")} (
                  {!isNaN(Math.round((avgRate + Number.EPSILON) * 100) / 100)
                    ? Math.round((avgRate + Number.EPSILON) * 100) / 100
                    : null}
                  /5.0)
                </p>
              </div>
            </div>
            {/* <div className='col-md-6 text-center'>
              <div
                style={{
                  position: "relative",
                  textAlign: "center",
                  color: "white",
                }}
              >
                <img src='/assets/special.png' height='150px' width='200px' />
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  <p>{i18n.t("specialPrices")}</p>
                  <p>{i18n.t("forQuan")}</p>
                </div>
              </div>
            </div> */}
          </div>
          <div
            className='mt-5'
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <input
              type='number'
              className='form-control w-25 mr-5 ml-5'
              placeholder='Quantity'
              onKeyDown={blockInvalidChar}
              min='1'
              onChange={(e) => {
                if (e.target.value !== "0") setItemQuantityCart(e.target.value);
                else {
                  quantityNotZero();
                }
              }}
            />
            <button
              onClick={() => addToCart(productData.id)}
              className='add-cart-btn  pr-5 pl-5 pt-2 pb-2 btnClickWhite'
            >
              {i18n.t("addToCart")}
            </button>
          </div>
        </div>
      </div>
      <div className='row' data-aos='fade-up'>
        <h5>{i18n.t("prdOverView")}</h5>
      </div>
      <div className='row mt-5' data-aos='fade-up'>
        <h6>{i18n.t("aboutPrd")}</h6>
      </div>
      <div className='row mt-3' data-aos='fade-up'>
        <p>{productData.about}</p>
      </div>
      <div className='row mt-3' data-aos='fade-up'>
        <div className='col-md-3'>
          {productData["productProperties"] ? (
            <>
              {productData.productProperties.map((p, i) => (
                <div
                  key={i}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <h6>{p.name}</h6>
                  <p>{p.value}</p>
                </div>
              ))}
            </>
          ) : null}
        </div>
      </div>

      <div className='row mt-5' data-aos='fade-up'>
        <h5>{i18n.t("prdDetails")}</h5>
      </div>
      <div className='row mt-3' data-aos='fade-up'>
        {/* <h6>{productData.details}</h6> */}
        {productData.details !== null &&
        productData.details !== "" &&
        productData.details !== undefined
          ? productData.details.split(".").map((chunck) => {
              return (
                <ul>
                  <li>{chunck}</li>
                  <br />
                </ul>
              );
            })
          : null}
      </div>

      <div className='row mt-5' data-aos='fade-up'>
        <h5>{i18n.t("techSpecs")}</h5>
      </div>
      <div className='row mt-3' data-aos='fade-up'>
        <div className='col-md-3'>
          {productData["productSpecs"] ? (
            <>
              {productData.productSpecs.map((s, i) => (
                <div
                  key={i}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <h6>{s.name}</h6>
                  <p>{s.value}</p>
                </div>
              ))}
            </>
          ) : null}
        </div>
      </div>

      <div className='row mt-5 text-center' data-aos='zoom-in'>
        <h5 className='col-md-3'>{i18n.t("ratePrd")}</h5>
        <div className='col-md-3'>
          <ReactStars
            onChange={ratingChanged}
            count={5}
            size={24}
            isHalf={true}
            activeColor='#2F5FA0'
          />
        </div>
        <div className='col-md-3'>
          <input
            type='text'
            min='1'
            placeholder='Add comment'
            className='form-control w-100 '
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
        <div className='col-md-3'>
          <button
            className='feedback-btn w-50 btnClick'
            onClick={() => sendRate(productData.id)}
          >
            {i18n.t("sendFeedback")}
          </button>
        </div>
      </div>
      <div className='row mt-5' data-aos='fade-up'>
        <h5>{i18n.t("prdRate&Review")}</h5>
      </div>

      {ratingList.length === 0 ? (
        <div className='row '>
          <div className='col-md-4'></div>
          <div className='col-md-6 continue-action mt-3 mb-5'>
            {i18n.t("noReview")}
          </div>
          <div className='col-md-2'></div>
        </div>
      ) : (
        <>
          <div className='row mt-3' data-aos='fade-up'>
            <div className='col-md-4'>
              <p>{i18n.t("overRating")}</p>
              <h5 style={{ color: "#2F5FA0" }}>
                {!isNaN(Math.round((avgRate + Number.EPSILON) * 100) / 100)
                  ? Math.round((avgRate + Number.EPSILON) * 100) / 100
                  : null}
              </h5>
              <p>
                {i18n.t("outOf")} 5.0 ({ratingList.length} {i18n.t("reviews")})
              </p>
            </div>
            <div className='col-md-4'>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                {!isNaN(avgRate) ? (
                  <ReactStars
                    onChange={ratingChanged}
                    count={5}
                    edit={false}
                    value={avgRate}
                    size={24}
                    isHalf={true}
                    activeColor='#2F5FA0'
                  />
                ) : null}
                {ratingList.length}
              </div>
            </div>
            <div className='col-md-4'></div>
          </div>
          <div className='row mt-3' data-aos='fade-down'>
            {ratingList.map((r, i) => (
              <div key={i} className='col-md-6'>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                  >
                    <img
                      alt=''
                      className='mr-2'
                      src='/assets/personsucces.png'
                      style={{ width: 80, height: 80, borderRadius: 15 }}
                    />
                    <p>{r.userName}</p>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <ReactStars
                      onChange={ratingChanged}
                      count={5}
                      size={24}
                      value={r.rateValue}
                      edit={false}
                      isHalf={true}
                      activeColor='#2F5FA0'
                    />
                    <p>{new Date(r.creationDate).toLocaleDateString()}</p>
                  </div>
                </div>
                <p>{r.comment}</p>
              </div>
            ))}
          </div>
        </>
      )}
      <div className='row mt-5' data-aos='fade-down'>
        <p
          className='col-md-8'
          style={{ fontWeight: "bolder", fontSize: "18px" }}
        >
          {i18n.t("relatedPrd")}
        </p>
        <div className='col-md-4'>
          <ReactPaginate
            previousLabel={i18n.t("<")}
            nextLabel={i18n.t(">")}
            pageCount={Math.ceil(totalRelatedCATCount / pageRelatedCATSize)}
            onPageChange={(e) => handlePaginationRelatedCATClick(e)}
            containerClassName={"pagination"}
            activeClassName={"active"}
            pageLinkClassName='hide-pagination'
          />
        </div>
      </div>

      <div className='row ' data-aos='fade-up'>
        {/* <p className='col-md-12'>{i18n.t("teamDesc")}</p> */}
        <div className='col-md-12 mb-5 mt-3'>
          <div className='row justify-content-center'>
            {relatedByCategory.map((p, i) => (
              <div
                key={i}
                className='col-md-2 mr-5 mt-1 btnScale'
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  borderRadius: 10,
                  border: "1px solid #E5E5E5",
                }}
                onClick={() => openProductDetails(p)}
              >
                {p.mainImage !== null ? (
                  <img
                    src={p.mainImage.path}
                    alt=''
                    height='70%'
                    width='100%'
                  />
                ) : (
                  <></>
                )}
                <div
                  style={{
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  <p className='card-title'>{p.name}</p>
                  <p style={{ color: "darkred" }}>
                    {p.price} {i18n.t("EGP")}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className='row mt-5' data-aos='fade-down'>
        <p
          className='col-md-8'
          style={{ fontWeight: "bolder", fontSize: "18px" }}
        >
          <div className='row'>
            <span className='mr-1 ml-1'>{i18n.t("moreBy")}</span>
            {productData.brand ? <p>{productData["brand"]["name"]}</p> : null}
          </div>
        </p>
        <div className='col-md-4'>
          <ReactPaginate
            previousLabel={i18n.t("<")}
            nextLabel={i18n.t(">")}
            pageCount={Math.ceil(totalRelatedPRDCount / pageRelatedPRDSize)}
            onPageChange={(e) => handlePaginationRelatedPRDClick(e)}
            containerClassName={"pagination"}
            activeClassName={"active"}
            pageLinkClassName='hide-pagination'
          />
        </div>
      </div>

      <div className='row' data-aos='fade-up'>
        {/* <p className='col-md-12'>{i18n.t("teamDesc")}</p> */}
        <div className='col-md-12 mb-5 mt-3'>
          <div className='row justify-content-center'>
            {relatedProducts.map((c, i) => (
              <div
                key={i}
                className='col-md-2 mr-5 mt-1 btnScale'
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  borderRadius: 10,
                  border: "1px solid #E5E5E5",
                }}
                onClick={() => openProductDetails(c)}
              >
                {c.mainImage !== null ? (
                  <img
                    src={c.mainImage.path}
                    alt=''
                    height='70%'
                    width='100%'
                  />
                ) : (
                  <></>
                )}
                <div
                  style={{
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  <p className='card-title'>{c.name}</p>
                  <p style={{ color: "darkred" }}>
                    {c.price} {i18n.t("EGP")}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;

import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bs-stepper/dist/css/bs-stepper.min.css";
import Stepper from "bs-stepper";
import "./completeOrder.css";
import { withRouter } from "react-router-dom";
import { OrderContext } from "../../context/orderContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import httpService from "../../services/httpService";
import { withTranslation } from "react-i18next";
import { t } from "i18next";
import * as _ from "lodash";

class CompleteOrder extends Component {
  //static contextType = OrderContext;
  addressDeleted = () => toast(t("addressDeleted"));
  addressUpdated = () => toast(t("addressUpdated"));

  selectPaymentMethod = () => toast(t("selectPaymentMethod"));
  selectedSavedAddress = () => toast(t("selectedSavedAddress"));
  chooseAddressFirst = () => toast(t("chooseAddressFirst"));

  cartListEmpty = () => toast(t("cartListEmpty"));

  constructor() {
    super();
    this.state = {
      change: 0,
      cartData: [],
      items: "",
      subTotal: "",
      vat: "",
      totalCost: "",
      address: "",
      addressId: "",
      mob: "",
      paymentMethod: "",
      errors: "",
      newAddress: false,
      savedAddresses: [],
      visa: {},
      redirect: "",
      submitOrderLoader: false,
    };
  }

  componentDidMount() {
    this.stepper = new Stepper(document.querySelector("#stepper1"), {
      linear: true,
      animation: true,
    });

    httpService
      .get(`${process.env.REACT_APP_SERVER_URL}useradress/list`)
      .then((res) => {
        this.setState({
          savedAddresses: _.uniqBy(res.data, "address", "phoneNumber"),
        });
      })
      .catch((err) => console.log(err));

    httpService
      .get(
        `${process.env.REACT_APP_SERVER_URL}UserSavedProducts/list?saveType=1`
      )
      .then((res) => {
        let items = 0;
        res.data.forEach((p) => (items += p.quantity));
        let sub = 0;
        res.data.forEach((p) => {
          if (p.product.hasDiscount === true)
            sub += p.quantity * (p.product.price - p.product.discountAmount);
          else sub += p.quantity * p.product.price;
        });
        let v = (sub * 14) / 100;
        let c = sub + v;
        this.setState({
          cartData: res.data,
          items: items,
          subTotal: Number((Math.round(sub * 100) / 100).toFixed(2)),
          vat: Number((Math.round(v * 100) / 100).toFixed(2)),
          totalCost: Number((Math.round(c * 100) / 100).toFixed(2)),
        });
        if (res.data.length === 0) {
          this.cartListEmpty();
          this.props.history.push("/");
        } else {
        }
      })
      .catch((err) => console.log(err));
    //console.log("context", this.context);
  }

  openNewAddress = () => {
    if (this.state.newAddress === false) this.setState({ newAddress: true });
    else if (this.state.newAddress === true)
      this.setState({ newAddress: false });
  };

  handleBackStep = () => {
    this.stepper.previous();
  };

  handleAddressSubmit = () => {
    // this.context[1]({
    //   items: this.context[0].items,
    //   subTotal: this.context[0].subTotal,
    //   vat: this.context[0].vat,
    //   total: this.context[0].total,
    //   paymentMethod: this.state.paymentMethod,
    //   address: this.state.address,
    //   phone: this.state.mob,
    // });
    if (this.state.address !== "" && this.state.mob !== "") {
      httpService
        .post(`${process.env.REACT_APP_SERVER_URL}useradress/insert`, {
          address: this.state.address,
          phoneNumber: this.state.mob,
          latitude: "",
          longitude: "",
        })
        .then((res) => {
          httpService
            .get(`${process.env.REACT_APP_SERVER_URL}useradress/list`)
            .then((res) => {
              let newAddId;
              newAddId = res.data.find((a) =>
                a.address === this.state.address ? a.id : null
              );
              this.setState({
                addressId: newAddId.id,
              });
            })
            .catch((err) => console.log(err));
          this.stepper.next();
        })
        .catch((err) => console.log(err));
    } else {
      this.setState({ errors: t("formValidation") });
      this.chooseAddressFirst();
    }

    //console.log("gdeda", this.context);
  };

  editSavedAddress = (data) => {
    httpService
      .put(`${process.env.REACT_APP_SERVER_URL}useradress/edit`, {
        id: data.id,
        address: data.address,
        phoneNumber: data.phoneNumber,
        latitude: "",
        longitude: "",
      })
      .then((res) => {
        this.setState({ change: this.state.change + 1 });
        this.addressUpdated();
      })
      .catch((err) => console.log(err));
  };

  deleteSavedAddress = (data) => {
    httpService
      .delete(`${process.env.REACT_APP_SERVER_URL}useradress/delete/${data.id}`)
      .then((res) => {
        this.addressDeleted();
        httpService
          .get(`${process.env.REACT_APP_SERVER_URL}useradress/list`)
          .then((res) => {
            this.setState({
              savedAddresses: res.data,
            });
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  handleSelectAddress = (data) => {
    this.setState({
      addressId: data.id,
      address: data.address,
      mob: data.phoneNumber,
    });
    this.selectedSavedAddress();
    setTimeout(() => {
      this.stepper.next();
    }, 2000);
  };

  handlePaymentMethod = (val) => {
    this.setState({
      paymentMethod: val,
    });
  };

  handleOrderSubmit = () => {
    this.setState({
      submitOrderLoader: true,
    });
    if (
      this.state.paymentMethod !== "" &&
      this.state.address !== "" &&
      this.state.addressId !== "" &&
      this.state.mob !== ""
    ) {
      //Bank
      if (this.state.paymentMethod === "0") {
        httpService
          .post(`${process.env.REACT_APP_SERVER_URL}order/insert`, {
            PaymentMethodEnum: Number(this.state.paymentMethod),
            UserAdressId: this.state.addressId,
            Vat: Number((Math.round(this.state.vat * 100) / 100).toFixed(2)),
            Total: Number(
              (Math.round(this.state.totalCost * 100) / 100).toFixed(2)
            ),
            SubTotal: Number(
              (Math.round(this.state.subTotal * 100) / 100).toFixed(2)
            ),
            ShippingFees: 0,
            OrderProducts: this.state.cartData.map((p) => ({
              ProductId: p.product.id,
              Quantity: p.quantity,
            })),
          })
          .then((res) => {
            this.setState({ visa: res.data.formData });
            document.getElementById("payment_confirmation").submit();
            this.setState({
              submitOrderLoader: false,
            });
          })
          .catch((e) => {
            console.log(e);
            this.setState({
              submitOrderLoader: false,
            });
          });
      }
      //Cash
      else if (this.state.paymentMethod === "1") {
        //call api
        httpService
          .post(`${process.env.REACT_APP_SERVER_URL}order/insert`, {
            PaymentMethodEnum: Number(this.state.paymentMethod),
            UserAdressId: this.state.addressId,
            Vat: Number((Math.round(this.state.vat * 100) / 100).toFixed(2)),
            Total: Number(
              (Math.round(this.state.totalCost * 100) / 100).toFixed(2)
            ),
            SubTotal: Number(
              (Math.round(this.state.subTotal * 100) / 100).toFixed(2)
            ),
            ShippingFees: 0,
            OrderProducts: this.state.cartData.map((p) => ({
              ProductId: p.product.id,
              Quantity: p.quantity,
            })),
          })
          .then((res) => {
            this.stepper.next();
            this.setState({
              submitOrderLoader: false,
            });
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              submitOrderLoader: false,
            });
          });
      }
      //Valu
      else if (this.state.paymentMethod === "2") {
        //valu
        httpService
          .post(`${process.env.REACT_APP_SERVER_URL}Order/paymentvalu`, {
            PaymentMethodEnum: Number(this.state.paymentMethod),
            UserAdressId: this.state.addressId,
            Vat: Number((Math.round(this.state.vat * 100) / 100).toFixed(2)),
            Total: Number(
              (Math.round(this.state.totalCost * 100) / 100).toFixed(2)
            ),
            SubTotal: Number(
              (Math.round(this.state.subTotal * 100) / 100).toFixed(2)
            ),
            ShippingFees: 0,
            OrderProducts: this.state.cartData.map((p) => ({
              ProductId: p.product.id,
              Quantity: p.quantity,
            })),
          })
          .then((res) => {
            this.setState({ redirect: res.data.redirect_url });
            document.getElementById("payment_confirmation_valu").submit();
            httpService
              .post(`${process.env.REACT_APP_SERVER_URL}order/insert`, {
                PaymentMethodEnum: Number(this.state.paymentMethod),
                UserAdressId: this.state.addressId,
                Vat: Number(
                  (Math.round(this.state.vat * 100) / 100).toFixed(2)
                ),
                Total: Number(
                  (Math.round(this.state.totalCost * 100) / 100).toFixed(2)
                ),
                SubTotal: Number(
                  (Math.round(this.state.subTotal * 100) / 100).toFixed(2)
                ),
                ShippingFees: 0,
                OrderProducts: this.state.cartData.map((p) => ({
                  ProductId: p.product.id,
                  Quantity: p.quantity,
                })),
              })
              .then((res) => {
                this.stepper.next();
                this.setState({
                  submitOrderLoader: false,
                });
              })
              .catch((err) => {
                console.log(err);
                this.setState({
                  submitOrderLoader: false,
                });
              });
            this.setState({
              submitOrderLoader: false,
            });
          })
          .catch((e) => {
            console.log(e);
            this.setState({
              submitOrderLoader: false,
            });
          });
      }
    } else {
      this.selectPaymentMethod();
      this.setState({
        submitOrderLoader: false,
      });
    }
  };

  render() {
    const { t } = this.props;

    return (
      <div className='container'>
        <ToastContainer
          progressClassName='toastProgress'
          bodyClassName='toastBody'
          toastClassName='toastContainer'
        />

        <div className='row mt-5 mb-5'>
          <img
            alt=''
            src='/assets/completeorder.png'
            width='100%'
            height='80%'
          />
        </div>
        <div id='stepper1' className='bs-stepper'>
          <div className='row bs-stepper-header'>
            <div className='step ' data-target='#test-l-1'>
              <button
                className='step-trigger'
                style={{ flexDirection: "column" }}
              >
                <span className='step-img'>
                  <img
                    alt=''
                    src='/assets/shipping.png'
                    width='40%'
                    height='60%'
                  />
                </span>
                <span
                  className='bs-stepper-label'
                  style={{ color: "#2F5FA0", fontSize: "14px" }}
                >
                  {t("shippingAddress")}
                </span>
              </button>
            </div>
            <div className='line '></div>
            <div className='step ' data-target='#test-l-2'>
              <button
                className='step-trigger'
                style={{ flexDirection: "column" }}
              >
                <span className='step-img'>
                  <img
                    alt=''
                    src='/assets/payment.png'
                    width='40%'
                    height='60%'
                  />
                </span>
                <span
                  className='bs-stepper-label'
                  style={{ color: "#2F5FA0", fontSize: "14px" }}
                >
                  {t("paymentMethod")}
                </span>
              </button>
            </div>
            <div className='line '></div>
            <div className='step ' data-target='#test-l-3'>
              <button
                className='step-trigger'
                style={{ flexDirection: "column" }}
              >
                <span className='step-img'>
                  <img
                    alt=''
                    src='/assets/success.png'
                    width='40%'
                    height='60%'
                  />
                </span>
                <span
                  className='bs-stepper-label'
                  style={{ color: "#2F5FA0", fontSize: "14px" }}
                >
                  {t("orderPlaced")}
                </span>
              </button>
            </div>
          </div>
          <div className='bs-stepper-content'>
            <div>
              <div id='test-l-1' className='content'>
                <div className='mb-3 mt-3'>
                  <h5>
                    {t("savedAddresses")} ({this.state.savedAddresses.length}){" "}
                  </h5>
                </div>
                {this.state.savedAddresses.length === 0 ? (
                  <h5 className='empty-action'>
                    No saved address yet, please add your shipping address.
                  </h5>
                ) : (
                  this.state.savedAddresses.map((a) => {
                    let iframeSrc = `https://www.google.com/maps/embed/v1/place?key=AIzaSyCcWcZYyXrQcHiPX7H90-HvJP17frIpXs4&q=${a.address}&zoom=15`;
                    return (
                      <div
                        className='row'
                        style={{ border: "1px solid #E5E5E5" }}
                      >
                        <div className='col-md-6 mt-5 mb-5'>
                          <div className='row'>
                            <iframe
                              className='col-md-6 ml-2'
                              height='100%'
                              loading='lazy'
                              allowFullScreen
                              src={iframeSrc}
                            ></iframe>
                            <div className='col-md-4'>
                              <p>{a.address}</p>
                              <p>{a.phoneNumber}</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-4'></div>
                        <div className='col-md-2'>
                          <div
                            className='w-100 mt-5 mb-5'
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <button
                              className='delete-add-btn mb-3 btnClick'
                              onClick={() => this.deleteSavedAddress(a)}
                            >
                              {t("remove")}
                            </button>
                            <button
                              className='edit-add-btn btnClickWhite'
                              data-toggle='modal'
                              data-target='#exampleModalCenter'
                            >
                              {t("edit")}
                            </button>
                            <button
                              className='delete-add-btn mt-3 btnClick'
                              onClick={() => this.handleSelectAddress(a)}
                            >
                              {t("select")}
                            </button>
                            <div
                              className='modal fade'
                              id='exampleModalCenter'
                              tabindex='-1'
                              role='dialog'
                              aria-labelledby='exampleModalCenterTitle'
                              aria-hidden='true'
                            >
                              <div
                                className='modal-dialog modal-dialog-centered'
                                role='document'
                              >
                                <div className='modal-content'>
                                  <div className='modal-header'>
                                    <h5
                                      className='modal-title'
                                      id='exampleModalLongTitle'
                                    >
                                      Update your address
                                    </h5>
                                    <button
                                      type='button'
                                      className='close'
                                      data-dismiss='modal'
                                      aria-label='Close'
                                    >
                                      <span aria-hidden='true'>&times;</span>
                                    </button>
                                  </div>
                                  <div className='modal-body'>
                                    <input
                                      placeholder={a.address}
                                      onChange={(e) =>
                                        (a.address = e.target.value)
                                      }
                                      className='form-control w-50 m-3'
                                    />
                                    <input
                                      placeholder={a.phoneNumber}
                                      onChange={(e) =>
                                        (a.phoneNumber = e.target.value)
                                      }
                                      className='form-control w-50 m-3'
                                    />
                                  </div>
                                  <div className='modal-footer'>
                                    <button
                                      type='button'
                                      className='btn btn-secondary'
                                      data-dismiss='modal'
                                    >
                                      Close
                                    </button>
                                    <button
                                      type='button'
                                      style={{
                                        backgroundColor: "#2F5FA0",
                                        color: "white",
                                        border: "1px solid #2F5FA0",
                                        padding: ".5rem",
                                      }}
                                      data-dismiss='modal'
                                      onClick={() => this.editSavedAddress(a)}
                                    >
                                      Save changes
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}

                {this.state.newAddress === true ? (
                  <div className='mt-5 mb-5 '>
                    <div className='form-group'>
                      <input
                        type='text'
                        className='form-control'
                        placeholder={t("yourAddress")}
                        value={this.state.address}
                        onChange={(e) =>
                          e.target.value !== undefined
                            ? this.setState({ address: e.target.value })
                            : this.setState({
                                errors: t("addressValidation"),
                              })
                        }
                      />
                    </div>
                    <div className='form-group'>
                      <input
                        type='text'
                        className='form-control'
                        id='exampleInputPassword'
                        placeholder={t("phoneNumber")}
                        value={this.state.mob}
                        onChange={(e) =>
                          e.target.value !== undefined
                            ? this.setState({ mob: e.target.value })
                            : this.setState({
                                errors: t("phoneNumberValidation"),
                              })
                        }
                      />
                    </div>
                    <div className='form-group' style={{ color: "red" }}>
                      {this.state.errors}
                    </div>
                  </div>
                ) : null}
                <div className='row mt-5 mb-5'>
                  {this.state.newAddress === true ? (
                    <button
                      className='m-3 w-25 delete-add-btn btnClick'
                      onClick={this.openNewAddress}
                    >
                      {t("cancel")}
                    </button>
                  ) : (
                    <button
                      className='m-3 w-25 delete-add-btn btnClick'
                      onClick={this.openNewAddress}
                    >
                      {t("newAddress")}
                    </button>
                  )}
                </div>
                <div className='row mt-5 mb-5'>
                  <div className='col-md-6'></div>
                  <div className='col-md-6 text-center text-md-right'>
                    <button
                      className='w-50 edit-add-btn btnClickWhite'
                      onClick={this.handleAddressSubmit}
                    >
                      {t("proceed")}
                    </button>
                  </div>
                </div>
              </div>
              <div id='test-l-2' className='content'>
                <div className='row'>
                  <div className='col-md-8'>
                    <h5 className='mb-5 mt-3'>{t("paymentMethod")}</h5>
                    <div className='row'>
                      <div
                        className='col-md-4 p-3 m-3'
                        style={{ border: "1px solid #2F5FA0" }}
                      >
                        <div
                          className='radio'
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                          }}
                        >
                          <input
                            value='visa'
                            onClick={(e) => this.handlePaymentMethod("0")}
                            className='mt-1'
                            type='radio'
                            name='optradio'
                            id='visa'
                          />
                          <label for='visa'>{t("creditCard")}</label>
                        </div>
                      </div>
                      <div
                        className='col-md-4 p-3 m-3'
                        style={{ border: "1px solid #2F5FA0" }}
                      >
                        <div
                          className='radio'
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                          }}
                        >
                          <input
                            value='cash'
                            onClick={(e) => this.handlePaymentMethod("1")}
                            className='mt-1'
                            type='radio'
                            name='optradio'
                            id='cash'
                          />
                          <label for='cash'>{t("cashOnDelivery")}</label>
                        </div>
                      </div>
                      <div
                        className='col-md-4 p-3 m-3'
                        style={{ border: "1px solid #2F5FA0" }}
                      >
                        <div
                          className='radio'
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                          }}
                        >
                          <input
                            value='valu'
                            onClick={(e) => this.handlePaymentMethod("2")}
                            className='mt-1'
                            type='radio'
                            name='optradio'
                            id='valu'
                          />
                          <label for='valu'>{t("valuInstallment")}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <h5 className='mb-5 mt-2'>{t("orderSummary")}</h5>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>{t("shippingAddress")}</p>
                      <p>{this.state.address}</p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>{t("phoneNumber")}</p>
                      <p>{this.state.mob}</p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>{t("items")}</p>
                      <p>
                        {this.state.items} {t("items")}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>{t("subtotal")}</p>
                      <p>
                        {(Math.round(this.state.subTotal * 100) / 100).toFixed(
                          2
                        )}{" "}
                        {t("EGP")}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>{t("estimatedVat")} (14%)</p>
                      <p>
                        {(Math.round(this.state.vat * 100) / 100).toFixed(2)}{" "}
                        {t("EGP")}
                      </p>
                    </div>
                    {/* <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>Shipping fees</p>
                      <p>50.00 EGP</p>
                    </div> */}
                    {/* <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>Discount</p>
                      <p>70 EGP</p>
                    </div> */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>{t("total")}</p>
                      <p>
                        {(Math.round(this.state.totalCost * 100) / 100).toFixed(
                          2
                        )}
                        {t("EGP")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className='row mt-5 mb-5'>
                  <div className='col-md-6'>
                    <button
                      className='mb-3 w-50 delete-add-btn btnClick'
                      onClick={this.handleBackStep}
                    >
                      {t("back")}
                    </button>
                  </div>
                  <div className='col-md-6 text-center mt-5 text-md-right'>
                    {this.state.submitOrderLoader ? (
                      <div className='text-center mt-5 mb-5'>
                        <div
                          className='spinner-border text-primary'
                          role='status'
                        >
                          <span className='sr-only'>Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <button
                        className='w-50 edit-add-btn btnClickWhite'
                        type='submit'
                        onClick={this.handleOrderSubmit}
                      >
                        {t("placeOrder")}
                      </button>
                    )}

                    {/* Bank */}
                    <form
                      id='payment_confirmation'
                      action='https://secureacceptance.cybersource.com/pay'
                      method='post'
                    >
                      <input
                        type='hidden'
                        id='access_key'
                        name='access_key'
                        value={this.state.visa.access_key}
                      />
                      <input
                        type='hidden'
                        id='profile_id'
                        name='profile_id'
                        value={this.state.visa.profile_id}
                      />
                      <input
                        type='hidden'
                        id='transaction_uuid'
                        name='transaction_uuid'
                        value={this.state.visa.transaction_uuid}
                      />
                      <input
                        type='hidden'
                        id='signed_field_names'
                        name='signed_field_names'
                        value={this.state.visa.signed_field_names}
                      />
                      <input
                        type='hidden'
                        id='unsigned_field_names'
                        name='unsigned_field_names'
                        value={this.state.visa.unsigned_field_names}
                      />
                      <input
                        type='hidden'
                        id='signed_date_time'
                        name='signed_date_time'
                        value={this.state.visa.signed_date_time}
                      />
                      <input
                        type='hidden'
                        id='locale'
                        name='locale'
                        value='en'
                      />
                      <input
                        type='hidden'
                        id='transaction_type'
                        name='transaction_type'
                        value={this.state.visa.transaction_type}
                      />
                      <input
                        type='hidden'
                        id='reference_number'
                        name='reference_number'
                        value={this.state.visa.reference_number}
                      />
                      <input
                        type='hidden'
                        id='amount'
                        name='amount'
                        value={this.state.visa.amount}
                      />
                      <input
                        type='hidden'
                        id='currency'
                        name='currency'
                        value={this.state.visa.currency}
                      />
                      {/* <input
                        type='hidden'
                        id={this.state.visa.submit}
                        name={this.state.visa.submit}
                        value={this.state.visa.submit}
                      /> */}
                      <input
                        type='hidden'
                        id='signature'
                        name='signature'
                        value={this.state.visa.signature}
                      />

                      {/* <input
                        type='submit'
                        style={{ display: "none" }}
                        value='Confirm'
                      /> */}
                    </form>
                    {/* Valu */}
                    <form
                      id='payment_confirmation_valu'
                      action={this.state.redirect}
                      method='post'
                    >
                      {/* <input
                        type='hidden'
                        id='access_key'
                        name='access_key'
                        value={this.state.visa.access_key}
                      />
                      <input
                        type='hidden'
                        id='profile_id'
                        name='profile_id'
                        value={this.state.visa.profile_id}
                      />
                      <input
                        type='hidden'
                        id='transaction_uuid'
                        name='transaction_uuid'
                        value={this.state.visa.transaction_uuid}
                      />
                      <input
                        type='hidden'
                        id='signed_field_names'
                        name='signed_field_names'
                        value={this.state.visa.signed_field_names}
                      />
                      <input
                        type='hidden'
                        id='unsigned_field_names'
                        name='unsigned_field_names'
                        value={this.state.visa.unsigned_field_names}
                      />
                      <input
                        type='hidden'
                        id='signed_date_time'
                        name='signed_date_time'
                        value={this.state.visa.signed_date_time}
                      />
                      <input
                        type='hidden'
                        id='locale'
                        name='locale'
                        value='en'
                      />
                      <input
                        type='hidden'
                        id='transaction_type'
                        name='transaction_type'
                        value={this.state.visa.transaction_type}
                      />
                      <input
                        type='hidden'
                        id='reference_number'
                        name='reference_number'
                        value={this.state.visa.reference_number}
                      />
                      <input
                        type='hidden'
                        id='amount'
                        name='amount'
                        value={this.state.visa.amount}
                      />
                      <input
                        type='hidden'
                        id='currency'
                        name='currency'
                        value={this.state.visa.currency}
                      /> */}
                      {/* <input
                        type='hidden'
                        id={this.state.visa.submit}
                        name={this.state.visa.submit}
                        value={this.state.visa.submit}
                      /> */}
                      {/* <input
                        type='hidden'
                        id='signature'
                        name='signature'
                        value={this.state.visa.signature}
                      /> */}

                      {/* <input
                        type='submit'
                        style={{ display: "none" }}
                        value='Confirm'
                      /> */}
                    </form>
                  </div>
                </div>
              </div>
              <div id='test-l-3' className='content text-center'>
                <div className='row mt-5 mb-5'>
                  <div className='col-md-4'></div>
                  <div
                    className='col-md-4'
                    style={{ position: "relative", top: 0, left: 0 }}
                  >
                    <img
                      alt=''
                      src='/assets/blob.png'
                      width='100%'
                      height='100%'
                      style={{
                        position: "relative",
                        top: 0,
                        left: 0,
                      }}
                    />
                    <img
                      style={{
                        position: "absolute",
                        top: "30px",
                        left: "30px",
                      }}
                      alt=''
                      src='/assets/orderdevice.png'
                      width='80%'
                      height='100%'
                    />
                  </div>
                  <div className='col-md-4'></div>
                </div>
                <div className='row justify-content-center m-5'>
                  <h5>{t("thanks")} 💙</h5>
                </div>
                <div className='row justify-content-center m-5'>
                  <p>{t("orderPlacedSuccessfully")}</p>
                </div>
                <div className='row justify-content-center m-5'>
                  <button
                    className='mb-3 w-25 delete-add-btn btnClick'
                    onClick={() => this.props.history.push("/orders")}
                  >
                    {t("myOrders")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CompleteOrder);

import "./App.css";
import { Route, Switch } from "react-router-dom";
import Home from "./components/home/home";
import NavBar from "./components/shared/navBar/navBar";
import StickyNavBar from "./components/shared/stickyNavBar/stickyNavBar";
import Footer from "./components/shared/footer/footer";
import AboutUs from "./components/aboutUs/aboutUs";
import ContactUs from "./components/contactUs/contactUs";
import CreateAccount from "./components/createAccount/createAccount";
import CategoryList from "./components/categoryList/categoryList";
import ProductDetails from "./components/productDetails/productDetails";
import React, { Suspense } from "react";
import Loader from "./loader";
import CartList from "./components/cartList/cartList";
import WishList from "./components/wishList/wishList";
import CompleteOrder from "./components/completeOrder/completeOrder";
import ForgetPassword from "./components/forgetPassword/forgetPassword";
import ResetPassword from "./components/resetPasswod/resetPassword";
import ServiceRequest from "./components/serviceRequest/serviceRequest";
import { useEffect, useState } from "react";
import Orders from "./components/orders/orders";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import OrderDetails from "./components/orders/orderDetails";
import VerifyEmail from "./components/verifyEmail/verifyEmail";
import ServiceList from "./components/serviceList/serviceList";
import OrderSubmitted from "./components/orderSubmitted/orderSubmitted";
import ProtectedRoute from "./components/protectedRoutes/protectedRoutes";
import QR from "./components/QR/QR";
import Profile from "./components/profile/profile";
import NotFound from "./components/notFound/notFound";
import QRSubmitted from "./components/QR/QRSubmitted";
import Terms from "./components/terms/Terms";

function App() {
  const [searchText, setSearchText] = useState("");
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (localStorage.getItem("currentLan") === null) {
      localStorage.setItem("currentLan", "en");
      document.body.dir = "ltr";
      window.location.reload();
    } else {
      document.body.dir =
        localStorage.getItem("currentLan") === "en" ? "ltr" : "rtl";
    }
  }, [searchText]);

  const handleSearch = (text) => {
    setSearchText(text);
    history.push({
      pathname: "/productdetails",
      state: { search: text },
    });
  };

  return (
    <Suspense fallback={<Loader />}>
      {window.location.href.includes("qr") ? (
        <>
          <NavBar />
          <Route path='/qr/:id' component={QR}></Route>
          <Route path='/qrsubmitted' component={QRSubmitted}></Route>
        </>
      ) : (
        <>
          <NavBar />
          <StickyNavBar handleSearchFilter={(text) => handleSearch(text)} />
          <Switch>
            <Route path='/createaccount' component={CreateAccount}></Route>
            <Route path='/forget' component={ForgetPassword}></Route>
            <Route
              path='/resetpassword/:userId/:token'
              component={ResetPassword}
            ></Route>
            <Route
              path='/verifyemail/:userId/:token'
              component={VerifyEmail}
            ></Route>
            <Route path='/aboutus' component={AboutUs}></Route>
            <Route path='/contactus' component={ContactUs}></Route>
            <Route path='/category' component={CategoryList}></Route>
            <Route path='/productdetails' component={ProductDetails}></Route>

            <ProtectedRoute
              path='/profile'
              component={Profile}
            ></ProtectedRoute>
            <ProtectedRoute path='/wish' component={WishList}></ProtectedRoute>
            <ProtectedRoute path='/orders' component={Orders}></ProtectedRoute>
            <ProtectedRoute
              path='/ordersubmitted'
              component={OrderSubmitted}
            ></ProtectedRoute>
            <ProtectedRoute
              path='/orderdetails'
              component={OrderDetails}
            ></ProtectedRoute>
            <ProtectedRoute path='/cart' component={CartList}></ProtectedRoute>
            <ProtectedRoute
              path='/order'
              component={CompleteOrder}
            ></ProtectedRoute>
            <Route path='/servicelist' component={ServiceList}></Route>
            <ProtectedRoute
              path='/servicerequest'
              component={ServiceRequest}
            ></ProtectedRoute>
            <Route path='/terms' component={Terms} exact></Route>
            <Route path='/' component={Home} exact></Route>
            <Route path='*' exact={true} component={NotFound} />
          </Switch>
          <div className='row'>
            <Footer />
          </div>
        </>
      )}
    </Suspense>
  );
}

export default App;

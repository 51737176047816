import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import httpService from "../../services/httpService";
import { useTranslation } from "react-i18next";

const ContactUs = () => {
  const { t, i18n } = useTranslation();

  const [values, setValues] = useState([]);
  const [mailBody, setMailBody] = useState("");
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");

  useEffect(() => {
    AOS.init({
      duration: 2200,
    });
    httpService
      .get(`${process.env.REACT_APP_SERVER_URL}contactinfo/list`)
      .then((res) => {
        setValues(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const sendEmail = () => {
    window.open(
      `mailto:Haidyaboelala1@gmail.com?subject=ElMagdSupport&body=user name: ${userName}, user email: ${userEmail},message: ${mailBody}`
    );
  };

  return (
    <div className='container'>
      <div className='row mt-5' data-aos='zoom-out'>
        <img alt='' src='/assets/contactus.png' width='100%' height='80%' />
      </div>
      <div className='row mt-5 mb-5'>
        <div className='col-md-6' data-aos='fade-right'>
          <p style={{ fontWeight: "bold" }}>{i18n.t("findUs")}</p>
          {values.map((v, i) =>
            v["contactInfoTypeEnum"] === 0 ? (
              <div
                key={i}
                className='mt-3'
                style={{ display: "flex", flexDirection: "row" }}
              >
                <img
                  alt=''
                  src='/assets/Location.png'
                  className='mr-2 ml-2 mt-2'
                  width='15'
                  height='15'
                />
                <p>{v["value"]}</p>
              </div>
            ) : v["contactInfoTypeEnum"] === 1 ? (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <img
                  alt=''
                  src='/assets/Mail.png'
                  width='15'
                  height='15'
                  className='mr-2 ml-2 mt-2'
                />
                <p>{v["value"]}</p>
              </div>
            ) : v["contactInfoTypeEnum"] === 2 ? (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <img
                  alt=''
                  src='/assets/Phone.png'
                  width='15'
                  height='15'
                  className='mr-2 ml-2 mt-2'
                />
                <p>{v["value"]}</p>
              </div>
            ) : null
          )}
          <p>
            <iframe
              width='70%'
              height='50%'
              loading='lazy'
              allowFullScreen
              src='https://www.google.com/maps/embed/v1/place?key=AIzaSyCcWcZYyXrQcHiPX7H90-HvJP17frIpXs4&q=42 Abd El-Khalik Tharwat&zoom=15'
            ></iframe>
          </p>
        </div>
        <div className='col-md-6' data-aos='fade-left'>
          <p style={{ fontWeight: "bold" }}>{i18n.t("sendUs")}</p>
          <form className='mt-3'>
            <div className='form-group'>
              <input
                type='text'
                className='form-control'
                id='exampleInputName'
                aria-describedby='nameHelp'
                placeholder={i18n.t("yourName")}
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
            <div className='form-group'>
              <input
                type='email'
                className='form-control'
                id='exampleInputEmail'
                aria-describedby='emailHelp'
                placeholder={i18n.t("yourEmail")}
                onChange={(e) => setUserEmail(e.target.value)}
              />
            </div>
            <div className='form-group'>
              <textarea
                className='form-control'
                id='exampleInputComment'
                placeholder={i18n.t("allEars")}
                onChange={(e) => setMailBody(e.target.value)}
              />
            </div>

            <button
              type='button'
              className='btn w-100'
              onClick={sendEmail}
              disabled={userName === "" || userEmail === "" || mailBody === ""}
              style={{ backgroundColor: "#2F5FA0", color: "white" }}
            >
              {i18n.t("send")}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;

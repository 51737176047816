import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import httpService from "../../services/httpService";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ResetPassword = () => {
  const { t, i18n } = useTranslation();
  const { userId, token } = useParams();
  const [loader, setLoader] = useState(false);

  const history = useHistory();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [forgetErrors, setForgetErrors] = useState("");

  const handleResetPasswordSubmit = () => {
    setLoader(true);

    if (password !== "" && confirmPassword !== "") {
      if (password === confirmPassword) {
        httpService
          .post(
            `${process.env.REACT_APP_SERVER_URL}authentication/resetpassword`,
            {
              userId: userId,
              token: token,
              newPassword: password,
            }
          )
          .then((res) => {
            setLoader(false);
            history.push("/createaccount");
          })
          .catch((err) => {
            setForgetErrors("Unhandled error occured, please try again");
            setLoader(false);
          });
      } else {
        setForgetErrors("Password doesnot match!!");
        setLoader(false);
      }
    } else {
      setForgetErrors(i18n.t("formValidation"));
      setLoader(false);
    }
  };

  return (
    <div className='container '>
      <div className='row mb-5 mt-5'>
        <div className='col-md-4'></div>
        <div className='col-md-4'>
          <form>
            <h5 className='mb-5'>Reset Password</h5>

            <div className='form-group'>
              <input
                type='password'
                className='form-control'
                placeholder='Your new password'
                value={password}
                onChange={(e) =>
                  e.target.value !== undefined
                    ? setPassword(e.target.value)
                    : setForgetErrors(i18n.t("passwordValidation"))
                }
              />
            </div>
            <div className='form-group'>
              <input
                type='password'
                className='form-control'
                placeholder='Confirm password'
                value={confirmPassword}
                onChange={(e) =>
                  e.target.value !== undefined
                    ? setConfirmPassword(e.target.value)
                    : setForgetErrors(i18n.t("passwordValidation"))
                }
              />
            </div>

            <div className='form-group' style={{ color: "red" }}>
              {forgetErrors}
            </div>
            {loader ? (
              <div className='text-center'>
                <div className='spinner-border text-primary' role='status'>
                  <span className='sr-only'>Loading...</span>
                </div>
              </div>
            ) : (
              <button
                type='button'
                onClick={handleResetPasswordSubmit}
                className='btn w-100'
                style={{ backgroundColor: "#2F5FA0", color: "white" }}
              >
                {i18n.t("send")}
              </button>
            )}
          </form>
        </div>
        <div className='col-md-4'></div>
      </div>
    </div>
  );
};
export default ResetPassword;

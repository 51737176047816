import React, { useState, useEffect } from "react";
import { UncontrolledCollapse } from "reactstrap";
import httpService from "../../services/httpService";
import "./categoryTree.css";
import { useHistory } from "react-router-dom";

const CategoryTree = (props) => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [categoriesFilter, setCategoriesFilter] = useState([]);
  const history = useHistory();

  var menuData = [];

  useEffect(() => {
    httpService
      .get(`${process.env.REACT_APP_SERVER_URL}category/listmenu`)
      .then((res) => {
        let tree = [];
        res.data.map((t) =>
          tree.push({ id: t.id, name: t.name, children: t.children })
        );
        menuData = tree;
        let menuItems = menuData.map((item, i) => {
          let menuItem = returnMenuItem(item, i);
          return menuItem;
        });
        setItems(menuItems);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [loading, categoriesFilter]);

  const returnMenuItem = (item, i) => {
    let menuItem;
    if (
      (item.children !== null && item.children.length <= 0) ||
      item.children === null ||
      item.children === undefined
    ) {
      menuItem = (
        <div className='item' key={i}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <input
              className='form-check-input'
              type='checkbox'
              id='flexCheckDefault'
              onChange={(e) => categoryFilter(item.id, e.target.checked)}
            />{" "}
            <p className='ml-4 mr-4'>{item.name}</p>
          </div>
        </div>
      );
    } else {
      let menuItemChildren = item.children.map((item, i) => {
        let menuItem = returnMenuItem(item, i);
        return menuItem;
      });
      menuItem = (
        <div key={i} className='item'>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <input
              className='form-check-input'
              type='checkbox'
              id='flexCheckDefault'
              onChange={(e) => categoryFilter(item.id, e.target.checked)}
            />{" "}
            <p className='toggler ml-4 mr-4' id={`toggle-menu-item-${item.id}`}>
              {item.name}{" "}
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='black'
                class='bi bi-caret-down-fill'
                viewBox='0 0 16 16'
              >
                <path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z' />
              </svg>
            </p>
          </div>
          <UncontrolledCollapse
            className='children'
            toggler={`#toggle-menu-item-${item.id}`}
          >
            {menuItemChildren}
          </UncontrolledCollapse>
        </div>
      );
    }
    return menuItem;
  };

  const categoryFilter = (categoryId, checked) => {
    let catArr = [...categoriesFilter];
    if (checked) {
      catArr.push(categoryId);
      setCategoriesFilter(catArr);
      props.changeCategoryFilterTree(catArr);
    } else {
      const index = catArr.indexOf(categoryId);
      if (index > -1) {
        catArr.splice(index, 1);
        setCategoriesFilter(catArr);
        props.changeCategoryFilterTree(catArr);
      }
    }
    history.push({
      pathname: "/category",
      state: {
        categoryyId: catArr,
      },
    });
  };

  return <div className='items'>{items}</div>;
};
export default CategoryTree;

import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import httpService from "../../services/httpService";
import { useHistory } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import "./createAccount.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

const CreateAccount = () => {
  const { t, i18n } = useTranslation();

  const successfullyLoggedIn = () => toast("You logged in successfully");
  const successfullyRegistered = () =>
    toast("You registered successfully, Please login with your account");
  const loginCancelled = () => toast("Login process cancelled");

  const [regLoader, setRegLoader] = useState(false);
  const [loginLoader, setLoginLoader] = useState(false);
  const [fbLoader, setFbLoader] = useState(false);
  const [googleLoader, setGoogleLoader] = useState(false);

  const history = useHistory();
  useEffect(() => {
    AOS.init({
      duration: 2200,
    });
    if (localStorage.getItem("token")) {
      history.push("/");
    } else {
    }
  }, []);

  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [registerErrors, setRegisterErrors] = useState("");

  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [loginErrors, setLoginErrors] = useState("");
  const [verifyRegistration, setVerifyRegistration] = useState("");

  const handleForgetPassword = () => {
    history.push("/forget");
  };
  const successGoogle = (response) => {
    setGoogleLoader(true);
    httpService
      .post(
        `${process.env.REACT_APP_SERVER_URL}authentication/socialregister`,
        {
          firstName: response["profileObj"]["givenName"],
          lastName: response["profileObj"]["familyName"],
          email: response["profileObj"]["email"],
          password: response["googleId"],
        }
      )
      .then((res) => {
        successfullyLoggedIn();
        localStorage.setItem("token", res["data"]["token"]);
        setGoogleLoader(false);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        loginCancelled();
        setGoogleLoader(false);
      });
  };

  const failureGoogle = (err) => {
    setGoogleLoader(false);
  };

  const responseFacebook = (response) => {
    setFbLoader(true);
    httpService
      .post(
        `${process.env.REACT_APP_SERVER_URL}authentication/socialregister`,
        {
          firstName: response["name"],
          lastName: "",
          email: response["email"],
          password: response["id"],
        }
      )
      .then((res) => {
        successfullyLoggedIn();
        localStorage.setItem("token", res["data"]["token"]);
        setFbLoader(false);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        setFbLoader(false);
        loginCancelled();
      });
  };

  const handleRegistrationSubmit = () => {
    setRegLoader(true);
    if (fName !== "" && lName !== "" && email !== "" && password !== "") {
      if (
        email
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      ) {
        httpService
          .post(`${process.env.REACT_APP_SERVER_URL}authentication/register`, {
            firstName: fName,
            lastName: lName,
            email: email,
            password: password,
          })
          .then((res) => {
            setVerifyRegistration(i18n.t("emailVerfication"));
            //successfullyRegistered();
            setRegLoader(false);
            setRegisterErrors("");
          })
          .catch((err) => {
            console.log(err);
            setRegLoader(false);
          });
      } else {
        setRegisterErrors(i18n.t("emailValidation"));
        setRegLoader(false);
      }
    } else {
      setRegisterErrors(i18n.t("formValidation"));
      setRegLoader(false);
    }
  };

  const handleLoginSubmit = () => {
    setLoginLoader(true);
    if (loginEmail !== "" && loginPassword !== "") {
      if (
        loginEmail
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      ) {
        httpService
          .post(`${process.env.REACT_APP_SERVER_URL}authentication/login`, {
            userName: loginEmail,
            password: loginPassword,
          })
          .then((res) => {
            if (res.status === 200) {
              localStorage.setItem("token", res["data"]["token"]);
              setLoginLoader(false);
              window.location.reload();
            } else {
              setLoginErrors(res);
            }
          })
          .catch((err) => {
            setLoginErrors(i18n.t("emailPasswordValidation"));
            setLoginLoader(false);
          });
      } else {
        setLoginErrors(i18n.t("emailValidation"));
        setLoginLoader(false);
      }
    } else {
      setLoginErrors(i18n.t("formValidation"));
      setLoginLoader(false);
    }
  };

  return (
    <div className='container'>
      <ToastContainer
        progressClassName='toastProgress'
        bodyClassName='toastBody'
        toastClassName='toastContainer'
      />

      <div className='row mt-5' data-aos='zoom-out'>
        <img alt='' src='/assets/createaccount.png' width='100%' height='80%' />
      </div>
      <div className='row mt-5' data-aos='zoom-in'>
        <div className='col-md-3'></div>
        <div
          className='col-md-6  p-3'
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            border: "1px solid #E5E5E5",
          }}
        >
          <div>
            <h6 style={{ color: "#3b5998" }}>{i18n.t("continueWith")}</h6>
          </div>
          {googleLoader ? (
            <div className='text-center'>
              <div className='spinner-border text-primary' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            </div>
          ) : (
            <GoogleLogin
              clientId='418043451650-rl0aqqp1p9ufg9cj8kt5c7h3onni6clk.apps.googleusercontent.com'
              buttonText='Google'
              onSuccess={successGoogle}
              onFailure={failureGoogle}
              cookiePolicy={"single_host_origin"}
              className='google-btn'
            />
          )}
          {fbLoader ? (
            <div className='text-center'>
              <div className='spinner-border text-primary' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            </div>
          ) : (
            <FacebookLogin
              appId='366056845282558'
              fields='name,email,picture'
              scope='public_profile,user_friends,email'
              callback={responseFacebook}
              cssClass='fb-button'
              icon='fa-facebook'
              textButton=' Facebook'
            />
          )}
        </div>

        <div className='col-md-3'></div>
      </div>
      <div className='row mt-5 mb-5'>
        <div className='col-md-5' data-aos='fade-right'>
          <h5>{i18n.t("createNewAccount")}</h5>
          <form className='mt-3'>
            <div className='form-group'>
              <input
                type='text'
                className='form-control'
                value={fName}
                placeholder={i18n.t("fstName")}
                onChange={(e) =>
                  e.target.value !== undefined
                    ? setFName(e.target.value)
                    : setRegisterErrors(i18n.t("fstNameValidation"))
                }
              />
            </div>
            <div className='form-group'>
              <input
                type='text'
                className='form-control'
                value={lName}
                placeholder={i18n.t("lstName")}
                onChange={(e) =>
                  e.target.value !== undefined
                    ? setLName(e.target.value)
                    : setRegisterErrors(i18n.t("lstNameValidation"))
                }
              />
            </div>
            <div className='form-group'>
              <input
                type='email'
                className='form-control'
                value={email}
                placeholder={i18n.t("email")}
                onChange={(e) =>
                  e.target.value !== undefined
                    ? setEmail(e.target.value)
                    : setRegisterErrors(i18n.t("emailValidation"))
                }
              />
            </div>
            <div className='form-group'>
              <input
                type='password'
                className='form-control'
                value={password}
                placeholder={i18n.t("password")}
                onChange={(e) =>
                  e.target.value !== undefined
                    ? setPassword(e.target.value)
                    : setRegisterErrors(i18n.t("passwordValidation"))
                }
              />
            </div>
            <div className='form-group' style={{ color: "red" }}>
              {registerErrors}
            </div>

            {/* <p style={{ color: "black", textAlign: "end", fontSize: 14 }}>
              Show password
            </p> */}
            {verifyRegistration !== "" ? <p>{verifyRegistration}</p> : null}
            {regLoader ? (
              <div className='text-center mt-5 mb-5'>
                <div className='spinner-border text-primary' role='status'>
                  <span className='sr-only'>Loading...</span>
                </div>
              </div>
            ) : (
              <button
                type='button'
                className='btn w-100'
                style={{ backgroundColor: "#2F5FA0", color: "white" }}
                onClick={handleRegistrationSubmit}
              >
                {i18n.t("createAccount")}
              </button>
            )}
          </form>
        </div>
        <div
          data-aos='zoom-in'
          className='col-md-2'
          style={{
            textAlign: "center",
            alignSelf: "center",
          }}
        >
          <h5>{i18n.t("or")}</h5>
        </div>
        <div className='col-md-5' data-aos='fade-left'>
          <h5>{i18n.t("login")}</h5>
          <form className='mt-3'>
            <div className='form-group'>
              <input
                type='email'
                className='form-control'
                placeholder={i18n.t("email")}
                value={loginEmail}
                onChange={(e) =>
                  e.target.value !== undefined
                    ? setLoginEmail(e.target.value)
                    : setLoginErrors(i18n.t("passwordValidation"))
                }
              />
            </div>
            <div className='form-group'>
              <input
                type='password'
                className='form-control'
                id='exampleInputPassword'
                placeholder={i18n.t("password")}
                value={loginPassword}
                onChange={(e) =>
                  e.target.value !== undefined
                    ? setLoginPassword(e.target.value)
                    : setLoginErrors(i18n.t("passwordValidation"))
                }
              />
            </div>
            <div className='form-group' style={{ color: "red" }}>
              {loginErrors}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
              }}
            >
              <p
                className='mr-4 btnHover'
                style={{ color: "black", fontSize: 14 }}
                onClick={handleForgetPassword}
              >
                {i18n.t("forgetPassword")}
              </p>
              {/* <p className='mr-4'>|</p> */}
              {/* <p style={{ color: "black", fontSize: 14 }}>Show password</p> */}
            </div>
            {loginLoader ? (
              <div className='text-center mt-5 mb-5'>
                <div className='spinner-border text-primary' role='status'>
                  <span className='sr-only'>Loading...</span>
                </div>
              </div>
            ) : (
              <button
                type='button'
                onClick={handleLoginSubmit}
                className='btn w-100'
                style={{ backgroundColor: "#2F5FA0", color: "white" }}
              >
                {i18n.t("login")}
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import "./navBar.css";
import jwt_decode from "jwt-decode";
import httpService from "../../../services/httpService";

const NavBar = (props) => {
  const [cartLength, setCartLength] = useState(0);
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [token, setToken] = useState("");

  useEffect(() => {
    if (localStorage.getItem("token")) {
      let decoded = jwt_decode(localStorage.getItem("token"));
      setToken(decoded);
    }
    httpService
      .get(
        `${process.env.REACT_APP_SERVER_URL}UserSavedProducts/list?saveType=1`
      )
      .then((res) => {
        setCartLength(res.data.length);
      })
      .catch((err) => console.log(err));
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("currentLan", lng);
    window.location.reload();
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.reload();
  };

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-md-3 navbar-item'>
          {localStorage.getItem("currentLan") === "en" ||
          localStorage.getItem("currentLan") === null ? (
            <img
              onClick={() => {
                if (!window.location.href.includes("qr")) history.push("/");
              }}
              alt=''
              src='/assets/logo.png'
              width='180'
              height='36'
              className='btnHover mt-2'
            />
          ) : (
            <img
              onClick={() => {
                if (!window.location.href.includes("qr")) history.push("/");
              }}
              alt=''
              className='btnHover mt-2'
              src='/assets/logo-ar.png'
              width='180'
              height='36'
            />
          )}
        </div>
        {localStorage.getItem("currentLan") === "en" ||
        localStorage.getItem("currentLan") === null ? (
          <div className='navbar-header mt-3'>
            <button
              type='button'
              className='navbar-toggle collapsed'
              data-toggle='collapse'
              data-target='#bs-example-navbar-collapse-1'
            >
              <img alt='' src='/assets/menu.png' width='25px' height='25px' />
            </button>
          </div>
        ) : (
          <div className='navbar-header-ar mt-3'>
            <button
              type='button'
              className='navbar-toggle collapsed'
              data-toggle='collapse'
              data-target='#bs-example-navbar-collapse-1'
            >
              <img alt='' src='/assets/menu.png' width='25px' height='25px' />
            </button>
          </div>
        )}

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          {localStorage.getItem("token") ? (
            <div
              className='col-md-1 '
              onClick={() => {
                if (!window.location.href.includes("qr")) history.push("/cart");
              }}
            >
              <img
                alt=''
                src='/assets/Cart.png'
                width='20'
                height='20'
                className='mr-2 mt-1'
              />
              <p style={{ fontWeight: "bold" }}>
                {i18n.t("cart")} ({cartLength})
              </p>
            </div>
          ) : null}

          <div className='col-md-2'>
            {localStorage.getItem("token") ? (
              <div className='dropdown show btnHover'>
                <p
                  style={{ fontWeight: "bold" }}
                  className='text-dark dropdown-toggle'
                  id='dropdownMenuLink'
                  data-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                >
                  <img
                    alt=''
                    src='/assets/Profile.png'
                    width='20'
                    height='20'
                    className='mr-2 mt-1'
                  />
                  {i18n.t("hi")}{" "}
                  {
                    token[
                      "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
                    ]
                  }
                </p>

                <div
                  className='dropdown-menu btnHover'
                  style={{ zIndex: 10 }}
                  aria-labelledby='dropdownMenuLink'
                >
                  <p
                    className='dropdown-item'
                    onClick={() => history.push("/profile")}
                  >
                    <img
                      alt=''
                      src='/assets/Profile.png'
                      width='20'
                      height='20'
                      className='mr-2'
                    />{" "}
                    {i18n.t("myAccount")}
                  </p>
                  <p
                    className='dropdown-item'
                    onClick={() => history.push("/orders")}
                  >
                    <img
                      alt=''
                      src='/assets/Cart.png'
                      width='20'
                      height='20'
                      className='mr-2'
                    />{" "}
                    {i18n.t("myOrders")}
                  </p>
                  <p
                    className='dropdown-item'
                    onClick={() => history.push("/wish")}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='20'
                      height='20'
                      fill='#2F5FA0'
                      className='bi bi-suit-heart mr-2'
                      viewBox='0 0 16 16'
                    >
                      <path d='m8 6.236-.894-1.789c-.222-.443-.607-1.08-1.152-1.595C5.418 2.345 4.776 2 4 2 2.324 2 1 3.326 1 4.92c0 1.211.554 2.066 1.868 3.37.337.334.721.695 1.146 1.093C5.122 10.423 6.5 11.717 8 13.447c1.5-1.73 2.878-3.024 3.986-4.064.425-.398.81-.76 1.146-1.093C14.446 6.986 15 6.131 15 4.92 15 3.326 13.676 2 12 2c-.777 0-1.418.345-1.954.852-.545.515-.93 1.152-1.152 1.595L8 6.236zm.392 8.292a.513.513 0 0 1-.784 0c-1.601-1.902-3.05-3.262-4.243-4.381C1.3 8.208 0 6.989 0 4.92 0 2.755 1.79 1 4 1c1.6 0 2.719 1.05 3.404 2.008.26.365.458.716.596.992a7.55 7.55 0 0 1 .596-.992C9.281 2.049 10.4 1 12 1c2.21 0 4 1.755 4 3.92 0 2.069-1.3 3.288-3.365 5.227-1.193 1.12-2.642 2.48-4.243 4.38z' />
                    </svg>
                    {i18n.t("wish")}
                  </p>
                  <p className='dropdown-item' onClick={handleLogout}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='20'
                      height='20'
                      fill='#2F5FA0'
                      className='bi bi-power mr-2'
                      viewBox='0 0 16 16'
                    >
                      <path d='M7.5 1v7h1V1h-1z' />
                      <path d='M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z' />
                    </svg>
                    {i18n.t("logout")}
                  </p>
                </div>
              </div>
            ) : window.location.href.includes("qr") ? (
              <></>
            ) : (
              <>
                <p
                  style={{ fontWeight: "bold" }}
                  onClick={() => history.push("/createaccount")}
                >
                  <img
                    alt=''
                    src='/assets/Profile.png'
                    width='20'
                    height='20'
                    className='mr-2'
                  />{" "}
                  {i18n.t("createAccount")}
                </p>
              </>
            )}
          </div>
          <div className='col-md-1 '>
            {localStorage.getItem("currentLan") === "en" ||
            localStorage.getItem("currentLan") === null ? (
              <div onClick={() => changeLanguage("ar")}>
                <p style={{ fontWeight: "bold" }}>
                  <img
                    alt=''
                    src='/assets/egypt.png'
                    width='20'
                    height='20'
                    className='mr-2'
                  />{" "}
                  {/* {i18n.t("ar")} */}
                </p>
              </div>
            ) : (
              <div onClick={() => changeLanguage("en")}>
                <p style={{ fontWeight: "bold" }}>
                  <img
                    alt=''
                    src='/assets/british.png'
                    width='20'
                    height='20'
                    className='mr-2'
                  />{" "}
                  {/* {i18n.t("en")} */}
                </p>
              </div>
            )}
          </div>
        </div>
        {/* //////////////web-view////////////////////// */}
        <div className='col-md-4'></div>
        <div
          className='col-md-1 mt-1'
          onClick={() => {
            if (!window.location.href.includes("qr")) history.push("/cart");
          }}
        >
          {localStorage.getItem("token") ? (
            <div className='nav-titles btnHover mt-2'>
              <img
                alt=''
                src='/assets/Cart.png'
                width='20'
                height='20'
                className='mr-2 mt-1'
              />
              <p style={{ fontWeight: "bold" }}>
                {i18n.t("cart")} ({cartLength})
              </p>
            </div>
          ) : null}
        </div>
        <div className='col-md-2 btnHover mt-1'>
          <div className='nav-titles'>
            {localStorage.getItem("token") ? (
              <div className='dropdown show btnHover'>
                <p
                  className='text-dark dropdown-toggle mt-2'
                  id='dropdownMenuLink'
                  data-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                  style={{ fontWeight: "bold" }}
                >
                  <img
                    alt=''
                    src='/assets/Profile.png'
                    width='20'
                    height='20'
                    className='mr-2'
                  />
                  {i18n.t("hi")}{" "}
                  {
                    token[
                      "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
                    ]
                  }
                </p>

                <div
                  className='dropdown-menu btnHover'
                  style={{ zIndex: 10 }}
                  aria-labelledby='dropdownMenuLink'
                >
                  <p
                    className='dropdown-item'
                    onClick={() => history.push("/profile")}
                  >
                    <img
                      alt=''
                      src='/assets/Profile.png'
                      width='20'
                      height='20'
                      className='mr-2'
                    />{" "}
                    {i18n.t("myAccount")}
                  </p>
                  <p
                    className='dropdown-item'
                    onClick={() => history.push("/orders")}
                  >
                    <img
                      alt=''
                      src='/assets/Cart.png'
                      width='20'
                      height='20'
                      className='mr-2'
                    />{" "}
                    {i18n.t("myOrders")}
                  </p>
                  <p
                    className='dropdown-item'
                    onClick={() => history.push("/wish")}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='20'
                      height='20'
                      fill='#2F5FA0'
                      className='bi bi-suit-heart mr-2'
                      viewBox='0 0 16 16'
                    >
                      <path d='m8 6.236-.894-1.789c-.222-.443-.607-1.08-1.152-1.595C5.418 2.345 4.776 2 4 2 2.324 2 1 3.326 1 4.92c0 1.211.554 2.066 1.868 3.37.337.334.721.695 1.146 1.093C5.122 10.423 6.5 11.717 8 13.447c1.5-1.73 2.878-3.024 3.986-4.064.425-.398.81-.76 1.146-1.093C14.446 6.986 15 6.131 15 4.92 15 3.326 13.676 2 12 2c-.777 0-1.418.345-1.954.852-.545.515-.93 1.152-1.152 1.595L8 6.236zm.392 8.292a.513.513 0 0 1-.784 0c-1.601-1.902-3.05-3.262-4.243-4.381C1.3 8.208 0 6.989 0 4.92 0 2.755 1.79 1 4 1c1.6 0 2.719 1.05 3.404 2.008.26.365.458.716.596.992a7.55 7.55 0 0 1 .596-.992C9.281 2.049 10.4 1 12 1c2.21 0 4 1.755 4 3.92 0 2.069-1.3 3.288-3.365 5.227-1.193 1.12-2.642 2.48-4.243 4.38z' />
                    </svg>
                    {i18n.t("wish")}
                  </p>
                  <p onClick={handleLogout} className='dropdown-item'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='20'
                      height='20'
                      fill='#2F5FA0'
                      className='bi bi-power mr-2'
                      viewBox='0 0 16 16'
                    >
                      <path d='M7.5 1v7h1V1h-1z' />
                      <path d='M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z' />
                    </svg>{" "}
                    {i18n.t("logout")}
                  </p>
                </div>
              </div>
            ) : window.location.href.includes("qr") ? (
              <></>
            ) : (
              <>
                <p
                  style={{ fontWeight: "bold" }}
                  className='mt-2'
                  onClick={() => history.push("/createaccount")}
                >
                  <img
                    alt=''
                    src='/assets/Profile.png'
                    width='20'
                    height='20'
                    className='mr-2'
                  />
                  {i18n.t("createAccount")}
                </p>
              </>
            )}
          </div>
        </div>
        <div className='col-md-2 btnHover mt-1'>
          <div className='nav-titles'>
            {localStorage.getItem("currentLan") === "en" ||
            localStorage.getItem("currentLan") === null ? (
              <div className='dropdown show btnHover mt-2'>
                <p
                  className='text-dark dropdown-toggle'
                  id='dropdownMenuLink'
                  data-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                  style={{ fontWeight: "bold" }}
                >
                  <img
                    alt=''
                    src='/assets/british.png'
                    width='20'
                    height='20'
                    className='mr-2'
                  />{" "}
                  {i18n.t("en")}
                </p>

                <div
                  className='dropdown-menu btnHover mt-2'
                  style={{
                    zIndex: 10,
                  }}
                  aria-labelledby='dropdownMenuLink'
                  onClick={() => changeLanguage("ar")}
                >
                  <p className='dropdown-item'>
                    <img
                      alt=''
                      src='/assets/egypt.png'
                      width='30'
                      height='20'
                      className='mr-2'
                    />
                    {i18n.t("ar")}
                  </p>
                </div>
              </div>
            ) : (
              <div className='dropdown show btnHover'>
                <p
                  className='text-dark dropdown-toggle mt-2'
                  id='dropdownMenuLink'
                  data-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                  style={{ fontWeight: "bold" }}
                >
                  <img
                    alt=''
                    src='/assets/egypt.png'
                    width='20'
                    height='20'
                    className='mr-2'
                  />{" "}
                  {i18n.t("ar")}
                </p>

                <div
                  className='dropdown-menu btnHover mt-2'
                  style={{ zIndex: 10 }}
                  aria-labelledby='dropdownMenuLink'
                  onClick={() => changeLanguage("en")}
                >
                  <p className='dropdown-item'>
                    <img
                      alt=''
                      src='/assets/british.png'
                      width='30'
                      height='20'
                      className='mr-2'
                    />
                    {i18n.t("en")}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;

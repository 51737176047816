import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import httpService from "../../services/httpService";
import AOS from "aos";
import { useTranslation } from "react-i18next";
import "./orders.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";

const Orders = () => {
  const { t, i18n } = useTranslation();
  const orderCancelled = () => toast(i18n.t("orderCancelled"));

  const [change, setChange] = useState(0);
  const [loader, setLoader] = useState(true);

  const [newProducts, setNewProducts] = useState([]);

  const history = useHistory();
  const [myOrders, setMyOrders] = useState([]);
  const [myServices, setMyServices] = useState([]);

  const [pageSize, setPageSize] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);

  const [pageSizeService, setPageSizeService] = useState(5);
  const [totalCountService, setTotalCountService] = useState(0);
  const [pageNumberService, setPageNumberService] = useState(0);

  const [pageMoreSize, setPageMoreSize] = useState(4);
  const [totalMoreCount, setTotalMoreCount] = useState(0);
  const [pageMoreNumber, setPageMoreNumber] = useState(0);

  useEffect(() => {
    AOS.init({
      duration: 2200,
    });

    httpService
      .post(`${process.env.REACT_APP_SERVER_URL}order/list`, {
        searchText: "",
        pageSize: pageSize,
        pageNumber: pageNumber,
        filter: {},
      })
      .then((res) => {
        setMyOrders(res.data.pageData);
        setTotalCount(res.data.totalCount);
        setLoader(false);
      })
      .catch((err) => console.log(err));

    httpService
      .post(`${process.env.REACT_APP_SERVER_URL}userservices/listpage`, {
        searchText: "",
        pageSize: pageSizeService,
        pageNumber: 0,
        filter: {},
      })
      .then((res) => {
        setMyServices(res.data.pageData);
        setTotalCountService(res.data.totalCount);
      })
      .catch((err) => console.log(err));

    httpService
      .post(`${process.env.REACT_APP_SERVER_URL}product/listpage`, {
        sort: 0,
        pageSize: pageMoreSize,
        pageNumber: 0,
      })
      .then((res) => {
        setTotalMoreCount(res.data.totalCount);
        setNewProducts(res.data["pageData"]);
      })
      .catch((err) => console.log(err));
  }, [change]);

  const cancelOrder = (orderId) => {
    httpService
      .put(`${process.env.REACT_APP_SERVER_URL}order/changestatus`, {
        orderId: orderId,
        orderStatusEnum: 3,
      })
      .then((res) => {
        setChange(change + 1);
        orderCancelled();
      })
      .catch((err) => console.log(err));
  };

  const openOrderDetails = (order) => {
    history.push({
      pathname: "/orderdetails",
      state: { data: order },
    });
    window.scrollTo(0, 0);
  };

  const openProductDetails = (productId) => {
    history.push({
      pathname: "/productdetails",
      state: { productId: productId },
    });
    window.scrollTo(0, 0);
  };

  const handlePaginationClick = (e) => {
    let pageNum = e.selected;
    httpService
      .post(`${process.env.REACT_APP_SERVER_URL}order/list`, {
        searchText: "",
        pageSize: pageSize,
        pageNumber: pageNum,
        filter: {},
      })
      .then((res) => {
        setMyOrders(res.data.pageData);
        setLoader(false);
      })
      .catch((err) => console.log(err));
  };

  const handlePaginationClickService = (e) => {
    let pageNum = e.selected;
    httpService
      .post(`${process.env.REACT_APP_SERVER_URL}userservices/listpage`, {
        searchText: "",
        pageSize: pageSizeService,
        pageNumber: pageNum,
        filter: {},
      })
      .then((res) => {
        setMyServices(res.data.pageData);
      })
      .catch((err) => console.log(err));
  };

  const handlePaginationMoreClick = (e) => {
    let pageNum = e.selected;
    httpService
      .post(`${process.env.REACT_APP_SERVER_URL}product/listpage`, {
        sort: 0,
        pageSize: pageMoreSize,
        pageNumber: pageNum,
      })
      .then((res) => {
        setNewProducts(res.data["pageData"]);
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className='container'>
      <ToastContainer
        progressClassName='toastProgress'
        bodyClassName='toastBody'
        toastClassName='toastContainer'
      />

      <div className='row mt-5' data-aos='zoom-out'>
        <img alt='' src='/assets/orders.png' width='100%' height='80%' />
      </div>
      <div className='row mt-5 mb-3'>
        <h5>
          {i18n.t("previousOrders")} ({totalCount})
        </h5>
      </div>
      {loader ? (
        <div className='text-center'>
          <div className='spinner-border text-primary' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      ) : (
        <>
          {myOrders.length === 0 ? (
            <div className='row justify-content-center mt-5 mb-5'>
              <button
                onClick={() => history.push("/")}
                className='continue-action'
              >
                <span>{i18n.t("emptyOrders")}</span>
              </button>
            </div>
          ) : (
            myOrders.map((o, i) => (
              <div
                key={i}
                className='row text-center m-5'
                style={{ border: "1px solid #E5E5E5" }}
              >
                <div className='col-md-2 mt-3 mb-3'>
                  <h6 className='mb-5'>{i18n.t("creationDate")}</h6>
                  <h6>{new Date(o.creationDate).toLocaleDateString()}</h6>
                </div>
                <div className='col-md-2 mt-3 mb-3'>
                  <h6 className='mb-5'>{i18n.t("subtotal")}</h6>
                  <h6>
                    {o.subTotal} {i18n.t("EGP")}
                  </h6>
                </div>
                <div className='col-md-2 mt-3 mb-3'>
                  <h6 className='mb-5'>{i18n.t("shippingFees")}</h6>
                  <h6>
                    {o.shippingFees} {i18n.t("EGP")}
                  </h6>
                </div>
                <div className='col-md-2 mt-3 mb-3'>
                  <h6 className='mb-5'>{i18n.t("vat")}</h6>
                  <h6>
                    {o.vat} {i18n.t("EGP")}
                  </h6>
                </div>
                <div className='col-md-2 mt-3 mb-3'>
                  <h6 className='mb-5'>{i18n.t("total")}</h6>
                  <h6>
                    {o.total} {i18n.t("EGP")}
                  </h6>
                </div>
                <div className='col-md-2 mt-3 mb-3'>
                  <h6 className='mb-5'>{i18n.t("status")}</h6>
                  {o.status === 0 ? (
                    <h6>{i18n.t("new")}</h6>
                  ) : o.status === 1 ? (
                    <h6>{i18n.t("shipped")}</h6>
                  ) : o.status === 2 ? (
                    <h6>{i18n.t("delivered")}</h6>
                  ) : o.status === 3 ? (
                    <h6>{i18n.t("cancelled")}</h6>
                  ) : o.status === 4 ? (
                    <h6>{i18n.t("paid")}</h6>
                  ) : o.status === 5 ? (
                    <h6>{i18n.t("confirmed ")}</h6>
                  ) : null}
                </div>

                <div className='col-md-12 mb-3 mt-3 text-center'>
                  {o.status === 0 ? (
                    <button
                      className='cancel-order-btn p-2 m-3 btnClick'
                      onClick={() => cancelOrder(o.id)}
                    >
                      {i18n.t("cancelOrder")}
                    </button>
                  ) : null}

                  <button
                    className='view-order-btn p-2 btnClickWhite'
                    onClick={() => openOrderDetails(o)}
                  >
                    {i18n.t("viewDetails")}
                  </button>
                </div>
              </div>
            ))
          )}
        </>
      )}
      {myOrders.length !== 0 ? (
        <div className='row mt-5 mb-5'>
          <div className='col-md-8'></div>
          <div className='col-md-4'>
            <ReactPaginate
              previousLabel={i18n.t("prev")}
              nextLabel={i18n.t("next")}
              pageCount={Math.ceil(totalCount / pageSize)}
              onPageChange={(e) => handlePaginationClick(e)}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      ) : null}

      {myServices.length > 0 ? (
        <>
          <div className='row mt-5 mb-3'>
            <h5>
              {i18n.t("previousServices")} ({myServices.length})
            </h5>
          </div>
          {myServices.map((s, i) => (
            <div
              key={i}
              className='row text-center m-5'
              style={{ border: "1px solid #E5E5E5" }}
            >
              <div className='col-md-2 mt-3 mb-3'>
                <h6 className='mb-5'>{i18n.t("creationDate")}</h6>
                <h6>{new Date(s.creationDate).toLocaleDateString()}</h6>
              </div>
              <div className='col-md-2 mt-3 mb-3'>
                <h6 className='mb-5'>{i18n.t("serviceName")}</h6>
                <h6>{s.service.name}</h6>
              </div>
              <div className='col-md-2 mt-3 mb-3'>
                <h6 className='mb-5'>{i18n.t("serviceAddress")}</h6>
                <h6>{s.address}</h6>
              </div>
              <div className='col-md-2 mt-3 mb-3'>
                <h6 className='mb-5'>{i18n.t("mobile")}</h6>
                <h6>{s.phoneNumber}</h6>
              </div>
              {s.attachments.length > 0 ? (
                <div className='col-md-2 mt-3 mb-3'>
                  <h6 className='mb-5'>{i18n.t("invoice")}</h6>
                  <a href={s.attachments[0].path} target='_blank'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='16'
                      fill='#2F5FA0'
                      className='btnScale bi bi-eye-fill'
                      viewBox='0 0 16 16'
                    >
                      <path d='M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z' />
                      <path d='M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z' />
                    </svg>
                  </a>
                </div>
              ) : null}

              <div className='col-md-2 mt-3 mb-3'>
                <h6 className='mb-5'>{i18n.t("status")}</h6>
                {s.serviceRequestEnum === 0 ? (
                  <h6>{i18n.t("new")}</h6>
                ) : s.serviceRequestEnum === 1 ? (
                  <h6>{i18n.t("inProgress")}</h6>
                ) : s.serviceRequestEnum === 2 ? (
                  <h6>{i18n.t("closed")}</h6>
                ) : null}
              </div>
            </div>
          ))}
        </>
      ) : null}

      {myServices.length !== 0 ? (
        <div className='row mt-5 mb-5'>
          <div className='col-md-8'></div>
          <div className='col-md-4'>
            <ReactPaginate
              previousLabel={i18n.t("prev")}
              nextLabel={i18n.t("next")}
              pageCount={Math.ceil(totalCountService / pageSizeService)}
              onPageChange={(e) => handlePaginationClickService(e)}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      ) : null}

      <div className='row mt-5' data-aos='fade-up'>
        <p className='col-md-8' style={{ fontWeight: "bolder" }}>
          {i18n.t("mayLike")}
        </p>
        <div className='col-md-4'>
          <ReactPaginate
            previousLabel={i18n.t("<")}
            nextLabel={i18n.t(">")}
            pageCount={Math.ceil(totalMoreCount / pageMoreSize)}
            onPageChange={(e) => handlePaginationMoreClick(e)}
            containerClassName={"pagination"}
            activeClassName={"active"}
            pageLinkClassName='hide-pagination'
          />
        </div>
      </div>

      <div className='row ' data-aos='zoom-out'>
        <p className='col-md-12'>{i18n.t("teamDesc")}</p>
        <div className='col-md-12 mb-5 mt-3'>
          <div className='row justify-content-center'>
            {newProducts.map((n, i) => (
              <div
                key={i}
                className='col-md-2 mr-5 mt-1 btnScale'
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  borderRadius: 10,
                  border: "1px solid #E5E5E5",
                }}
                onClick={() => openProductDetails(n.id)}
              >
                <img src={n.mainImage.path} alt='' height='70%' width='100%' />
                <div
                  style={{
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  <p className='card-title'>{n.name}</p>
                  <p style={{ color: "darkred" }}>
                    {n.price} {i18n.t("EGP")}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;

import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import httpService from "../../services/httpService";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ForgetPassword = () => {
  const { t, i18n } = useTranslation();
  const [loader, setLoader] = useState(false);

  const history = useHistory();
  const [email, setEmail] = useState("");
  const [forgetErrors, setForgetErrors] = useState("");
  const [checkEmail, setCheckEmail] = useState("");

  const handleForgetSubmit = () => {
    setLoader(true);
    if (email !== "") {
      if (
        email
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      ) {
        httpService
          .get(
            `${process.env.REACT_APP_SERVER_URL}authentication/forgetpassword?email=${email}`
          )
          .then((res) => {
            setForgetErrors("");
            setLoader(false);
            setCheckEmail(
              "Verification email sent to your email successfully, please check your inbox and spam folder too"
            );
          })
          .catch((err) => {
            setForgetErrors("Please enter a valid email!");
            setLoader(false);
          });
      } else {
        setForgetErrors(i18n.t("emailValidation"));
        setLoader(false);
      }
    } else {
      setForgetErrors(i18n.t("formValidation"));
      setLoader(false);
    }
  };

  return (
    <div className='container '>
      <div className='row mb-5 mt-5'>
        <div className='col-md-4'></div>
        <div className='col-md-4'>
          <form>
            <h5 className='mb-5'>{i18n.t("email")}</h5>

            <div className='form-group'>
              <input
                type='email'
                className='form-control'
                placeholder='Your email address'
                value={email}
                onChange={(e) =>
                  e.target.value !== undefined
                    ? setEmail(e.target.value)
                    : setForgetErrors(i18n.t("passwordValidation"))
                }
              />
            </div>

            <div className='form-group' style={{ color: "red" }}>
              {forgetErrors}
            </div>
            {checkEmail !== "" ? <p>{checkEmail}</p> : null}
            {loader ? (
              <div className='text-center'>
                <div className='spinner-border text-primary' role='status'>
                  <span className='sr-only'>Loading...</span>
                </div>
              </div>
            ) : (
              <button
                type='button'
                onClick={handleForgetSubmit}
                className='btn w-100'
                style={{ backgroundColor: "#2F5FA0", color: "white" }}
              >
                {i18n.t("send")}
              </button>
            )}
          </form>
        </div>
        <div className='col-md-4'></div>
      </div>
    </div>
  );
};

export default ForgetPassword;

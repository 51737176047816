import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AOS from "aos";
import { useTranslation } from "react-i18next";
import "./orders.css";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";

const OrderDetails = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    AOS.init({
      duration: 2200,
    });
  }, []);

  return (
    <div className='container'>
      <div className='row mt-5' data-aos='zoom-out'>
        <img alt='' src='/assets/orderDetails.png' width='100%' height='80%' />
      </div>
      <div className='row mt-5 mb-3'>
        <div className='col-md-3'>
          <h5>{i18n.t("orderDetails")}</h5>
        </div>
        <div className='col-md-3'>
          <p style={{ fontWeight: "bold" }}>{i18n.t("shippedAddress")}</p>
          <p>{location.state.data.userAdress.address}</p>
        </div>
        <div className='col-md-3'>
          <p style={{ fontWeight: "bold" }}>{i18n.t("mobile")}</p>
          <p>{location.state.data.userAdress.phoneNumber}</p>
        </div>
        <div className='col-md-3'>
          <p style={{ fontWeight: "bold" }}>{i18n.t("paymentType")}</p>
          {location.state.data.paymentMethodEnum === 0 ? (
            <p>{i18n.t("creditCard")}</p>
          ) : location.state.data.paymentMethodEnum === 1 ? (
            <p>{i18n.t("cashOnDelivery")}</p>
          ) : location.state.data.paymentMethodEnum === 2 ? (
            <p>{i18n.t("valuInstallment")}</p>
          ) : null}
        </div>
      </div>

      {location.state.data.orderProducts.map((p, i) => (
        <div
          key={i}
          className='row text-center m-5'
          style={{ border: "1px solid #E5E5E5" }}
        >
          <div className='col-md-2 mt-3 mb-3'>
            <h6 className='mb-5'>{i18n.t("name")}</h6>
            <h6>{p.product.name}</h6>
          </div>
          <div className='col-md-2 mt-3 mb-3'>
            <h6 className='mb-5'>{i18n.t("description")}</h6>
            <h6>{p.product.breif}</h6>
          </div>
          <div className='col-md-2 mt-3 mb-3'>
            <h6 className='mb-5'>{i18n.t("quantity")}</h6>
            <h6>{p.quantity}</h6>
          </div>
          <div className='col-md-2 mt-3 mb-3'>
            <h6 className='mb-5'>{i18n.t("color")}</h6>
            {p.product.productCurrentColor ? (
              <h6>{p.product.productCurrentColor.color.name}</h6>
            ) : null}
          </div>
          <div className='col-md-2 mt-3 mb-3'>
            <h6 className='mb-5'>{i18n.t("image")}</h6>
            {p.product.mainImage !== null ? (
              <img width='60' height='60' src={p.product.mainImage.path} />
            ) : null}
          </div>
          <div className='col-md-2 mt-3 mb-3'>
            <h6 className='mb-5'>{i18n.t("price")}</h6>
            <h6 style={{ color: "darkred" }}>
              {p.product.price} {i18n.t("EGP")}
            </h6>
          </div>
        </div>
      ))}
      <div className='col-md-12 mb-5 mt-5 text-center'>
        <button
          className='cancel-order-btn p-2 btnClick'
          onClick={() => {
            history.push("/orders");
            window.scrollTo(0, 0);
          }}
        >
          {i18n.t("back")}
        </button>
      </div>
    </div>
  );
};

export default OrderDetails;

import jwtDecode from "jwt-decode";

const tokenKey = "token";

export const setUserJWT = (jwt) => {
  localStorage.setItem(tokenKey, jwt);
};

export const getUserProperties = () => {
  try {
    const jwt = localStorage.getItem(tokenKey);
    return jwtDecode(jwt);
  } catch (error) {
    console.log(error);
  }
};

export function getUserJwt() {
  return localStorage.getItem(tokenKey);
}

export const logout = () => {
  localStorage.removeItem(tokenKey);
};

export default {
  setUserJWT,
  logout,
  getUserProperties,
  getUserJwt,
};

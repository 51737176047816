import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import httpService from "../../services/httpService";
import { useHistory, useParams } from "react-router-dom";
import "./QR.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const QR = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [mob, setMob] = useState("");
  const [comment, setComment] = useState("");
  const [formErrors, setFormErrors] = useState("");
  const [deviceData, setDeviceData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(true);
  const [radioValue, setRadioValue] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [submitClicked, setSubmitClicked] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [selectedContract, setSelectedContract] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  let formData = new FormData();
  let files = [];
  useEffect(() => {
    AOS.init({
      duration: 2200,
    });

    httpService
      .get(
        `${process.env.REACT_APP_SERVER_URL}MaintenanceProduct/details?id=${id}`
      )
      .then((res) => {
        setDeviceData(res.data);
        setPageLoader(false);
      })
      .catch((err) => console.log(err));
    httpService
      .get(`${process.env.REACT_APP_SERVER_URL}WarrantyContract/list`)
      .then((res) => {
        setContracts(res.data);
      })
      .catch((err) => console.log(err)); //get by id
  }, [pageLoader, loader, radioValue, submitClicked]);

  const handleRadioChange = (value) => {
    setRadioValue(value);
  };

  const handleFileSelected = (e) => {
    debugger;
    files = Array.from(e.target.files);
    setSelectedFile(files);
  };

  const complainForm = () => {
    return (
      <form className='mt-3'>
        <div className='form-group'>
          <div className='form-group'>
            <p>{i18n.t("chassisNo")}</p>
            <input
              type='text'
              className='form-control'
              value={deviceData.chassisNo}
              readonly
            />
          </div>
          <div className='form-group'>
            <p>{i18n.t("model")}</p>
            <input
              type='text'
              className='form-control'
              value={deviceData.model}
              readonly
            />
          </div>
          <p>{i18n.t("deviceAddress")}</p>
          <input
            type='text'
            className='form-control'
            value={deviceData.address}
            readonly
          />
        </div>
        <div className='form-group'>
          <p>{i18n.t("appealsChamber")}</p>
          <input
            type='text'
            className='form-control'
            value={deviceData.appealsChamber}
            readonly
          />
        </div>
        <div className='form-group'>
          <p>{i18n.t("districtProsecution")}</p>
          <input
            type='text'
            className='form-control'
            value={deviceData.districtProsecution}
            readonly
          />
        </div>
        <div className='form-group'>
          <p>{i18n.t("floorNo")}</p>
          <input
            type='text'
            className='form-control'
            value={deviceData.floorNo}
            readonly
          />
        </div>
        <div className='form-group'>
          <p>{i18n.t("publicProsecution")}</p>
          <input
            type='text'
            className='form-control'
            value={deviceData.publicProsecution}
            readonly
          />
        </div>
        <div className='form-group'>
          <p>{i18n.t("roomNo")}</p>
          <input
            type='text'
            className='form-control'
            value={deviceData.roomNo}
            readonly
          />
        </div>
        {radioValue == 0 || radioValue == 1 ? (
          <div className='form-group'>
            <p>{i18n.t("uploadInvoice")}</p>
            <div className='col-md-6'>
              <input
                onChange={(e) => handleFileSelected(e)}
                type='file'
                accept='image/png, image/gif, image/jpeg'
                multiple='multiple'
              />
            </div>
          </div>
        ) : null}
        {radioValue == 2 ? (
          <>
            <div className='form-group'>
              <p>
                {i18n.t("contractType")}
                <span style={{ color: "red" }}>*</span>
              </p>
              <div className='dropdown show'>
                <p
                  className='btn btn-outline-dark dropdown-toggle'
                  role='button'
                  id='dropdownMenuLink'
                  data-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                >
                  {i18n.t("contractTypes")}
                </p>
                <div class='dropdown-menu' aria-labelledby='dropdownMenuLink'>
                  {contracts
                    ? contracts.map((c) => (
                        <p
                          className='contract'
                          onClick={() => setSelectedContract(c)}
                        >
                          {c.name}: {c.description ? c.description : null}
                        </p>
                      ))
                    : null}
                </div>
                {selectedContract.id != "" &&
                selectedContract.id != null &&
                selectedContract.id != undefined ? (
                  <div style={{ color: "#2F5FA0", fontWeight: "bold" }}>
                    {i18n.t("selectedContract")} {selectedContract.name}
                  </div>
                ) : null}
              </div>
              {submitClicked &&
              (selectedContract.id == "" ||
                selectedContract.id == null ||
                selectedContract.id == undefined) ? (
                <span style={{ color: "red" }}>
                  {i18n.t("contractTypeerr")}
                </span>
              ) : null}
            </div>
            <div className='form-group'>
              <p>
                {i18n.t("contractStart")}
                <span style={{ color: "red" }}>*</span>
              </p>
              <DatePicker
                className='form-control'
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
              {submitClicked &&
              (startDate == "" ||
                startDate == null ||
                startDate == undefined) ? (
                <span style={{ color: "red" }}>
                  {i18n.t("contractStarterr")}
                </span>
              ) : null}
            </div>
          </>
        ) : null}
        <div className='form-group'>
          <p>
            {i18n.t("fullName")}
            <span style={{ color: "red" }}>*</span>
          </p>
          <input
            type='text'
            value={fullName}
            className='form-control'
            onChange={(e) =>
              e.target.value !== undefined
                ? setFullName(e.target.value)
                : setFormErrors(i18n.t("fullNameValidation"))
            }
          />
        </div>
        <div className='form-group'>
          <p>
            {i18n.t("phoneNumber")}
            <span style={{ color: "red" }}>*</span>
          </p>
          <input
            type='text'
            value={mob}
            className='form-control'
            onChange={(e) =>
              e.target.value !== undefined
                ? setMob(e.target.value)
                : setFormErrors(i18n.t("phoneNumberValidation"))
            }
          />
        </div>
        <div className='form-group'>
          <input
            type='email'
            value={email}
            className='form-control'
            placeholder={i18n.t("email")}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className='form-group'>
          <textarea
            type='text'
            value={comment}
            className='form-control'
            placeholder={i18n.t("comment")}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
        <div className='form-group' style={{ color: "red" }}>
          {formErrors}
        </div>
        {loader ? (
          <div className='text-center'>
            <div className='spinner-border text-primary' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
        ) : (
          <button
            type='button'
            className='btn w-100'
            style={{ backgroundColor: "#2F5FA0", color: "white" }}
            disabled={fullName === "" || mob === ""}
            onClick={handleQRIssueSubmit}
          >
            {i18n.t("send")}
          </button>
        )}
      </form>
    );
  };

  const handleQRIssueSubmit = () => {
    setLoader(true);
    setSubmitClicked(true);
    if (fullName !== "" && mob !== "") {
      //send request
      if (radioValue == 0 || radioValue == 1) {
        let object = {
          maintenanceProductId: id,
          email: email,
          name: fullName,
          mobile: mob,
          comment: comment,
          requestTypeEnum: radioValue == 0 ? 2 : radioValue == 1 ? 0 : null,
        };
        debugger;
        formData.append("data", JSON.stringify(object));
        if (selectedFile != null) {
          for (let index = 0; index < selectedFile.length; index++) {
            formData.append("file", selectedFile[index]);
          }
        }
        httpService
          .post(
            `${process.env.REACT_APP_SERVER_URL}MaintenanceRequest/insert`,
            formData
          )
          .then((res) => {
            if (res.status === 200) {
              history.push("/qrsubmitted");
            } else {
              alert(i18n.t("somethingWrong"));
            }
            setLoader(false);
          })
          .catch((err) => {
            console.log(err);
            setLoader(false);
          });
      } else if (
        radioValue == 2 &&
        startDate !== "" &&
        startDate != null &&
        startDate != undefined &&
        selectedContract.id !== "" &&
        selectedContract.id != null &&
        selectedContract.id != undefined
      ) {
        httpService
          .post(
            `${process.env.REACT_APP_SERVER_URL}warrantyrequest/InsertAnonymous`,
            {
              warrantyContractTypeId: selectedContract.id,
              productIds: [],
              maintanceProductIds: [deviceData.id],
              startDate: startDate,
              email: email,
              phone: mob,
              name: fullName,
            }
          )
          .then((res) => {
            if (res.status === 200) {
              history.push("/qrsubmitted");
            } else {
              alert(i18n.t("somethingWrong"));
            }
            setLoader(false);
          })
          .catch((err) => {
            console.log(err);
            setLoader(false);
          });
      }
    }
    setLoader(false);
  };

  return (
    <div className='container'>
      <div className='row justify-content-center mt-3'>
        <p className='mr-2' style={{ fontWeight: "bold", color: "#2F5FA0" }}>
          {i18n.t("goldenPartner")}
        </p>
      </div>
      <div className='row justify-content-center'>
        <img alt='' src='/assets/canon.png' style={{ width: 60, height: 40 }} />
      </div>
      {pageLoader ? (
        <div className='text-center mt-5 mb-5'>
          <div className='spinner-border text-primary' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      ) : (
        <div className='row mt-5 mb-5'>
          <div className='col-md-12 d-flex justify-content-center mb-5'>
            <div class='form-check col-md-4'>
              <input
                class='form-check-input'
                type='radio'
                name='flexRadioDefault'
                id='flexRadioDefault2'
                value='1'
                onClick={(e) => handleRadioChange(e.target.value)}
                defaultChecked
              />
              <label class='form-check-label mr-3 ml-3' for='flexRadioDefault2'>
                {i18n.t("qrMaintenance")}
              </label>
            </div>
            <div class='form-check col-md-4'>
              <input
                class='form-check-input'
                type='radio'
                name='flexRadioDefault'
                id='flexRadioDefault1'
                value='0'
                onClick={(e) => handleRadioChange(e.target.value)}
              />
              <label class='form-check-label mr-3 ml-3' for='flexRadioDefault1'>
                {i18n.t("qrInstall")}
              </label>
            </div>
            <div class='form-check col-md-4'>
              <input
                class='form-check-input'
                type='radio'
                name='flexRadioDefault'
                id='flexRadioDefault3'
                value='2'
                onClick={(e) => handleRadioChange(e.target.value)}
              />
              <label class='form-check-label mr-3 ml-3' for='flexRadioDefault3'>
                {i18n.t("qrContract")}
              </label>
            </div>
          </div>
          <div className='col-md-12' data-aos='fade-right'>
            {radioValue == 1 ? (
              <>
                <p style={{ fontWeight: "bold", fontSize: "18" }}>
                  {i18n.t("sendIssue")}
                </p>
                {complainForm()}
              </>
            ) : radioValue == 0 ? (
              <>
                <p style={{ fontWeight: "bold", fontSize: "18" }}>
                  {i18n.t("sendIssue")}
                </p>
                {complainForm()}
              </>
            ) : radioValue == 2 ? (
              <>
                <p style={{ fontWeight: "bold", fontSize: "18" }}>
                  {i18n.t("sendRequest")}
                </p>
                {complainForm()}
              </>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default QR;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./footer.css";
import httpService from "../../../services/httpService";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t, i18n } = useTranslation();

  const history = useHistory();
  const [categoryList, setCategoryList] = useState([]);
  const [brandList, setBrandList] = useState([]);

  useEffect(() => {
    httpService
      .get(`${process.env.REACT_APP_SERVER_URL}category/listmenu`)
      .then((res) => {
        setCategoryList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    httpService
      .get(`${process.env.REACT_APP_SERVER_URL}brand/list`)
      .then((res) => {
        setBrandList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const openTermsPage = () => {
    history.push("/terms");
    window.scrollTo(0, 0);
  };

  return (
    <div
      className='container-fluid main p-5'
      style={{ position: "relative", bottom: 0 }}
    >
      <div className='row'>
        <div className='col-md-3'>
          <p
            className='nameHeader'
            style={{ fontSize: "24", fontWeight: "bolder" }}
          >
            {i18n.t("magd")}
          </p>
          <p style={{ fontSize: "12" }}>{i18n.t("historyDesc")}</p>
          <p
            style={{
              fontSize: "12",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={openTermsPage}
          >
            {i18n.t("terms")}
          </p>
        </div>
        <div className='col-md-3'>
          <p style={{ fontSize: "24", fontWeight: "bolder" }}>
            {i18n.t("siteLinks")}
          </p>
          <p
            className='redirectLinks'
            onClick={() => {
              history.push("/");
              window.scrollTo(0, 0);
            }}
          >
            {i18n.t("home")}
          </p>
          {categoryList.map((c, i) => (
            <p
              key={i}
              className='redirectLinks'
              onClick={() => {
                history.push({
                  pathname: "/category",
                  state: {
                    categoryId: c.id,
                    categoryName: c.name,
                  },
                });
                window.scrollTo(0, 0);
              }}
            >
              {c.name}
            </p>
          ))}

          <p
            className='redirectLinks'
            onClick={() => {
              history.push("/servicelist");
              window.scrollTo(0, 0);
            }}
          >
            {i18n.t("services")}
          </p>
          <p
            className='redirectLinks'
            onClick={() => {
              history.push("/aboutus");
              window.scrollTo(0, 0);
            }}
          >
            {i18n.t("aboutUs")}
          </p>
          <p
            className='redirectLinks'
            onClick={() => {
              history.push("/contactus");
              window.scrollTo(0, 0);
            }}
          >
            {i18n.t("contactUs")}
          </p>
        </div>
        <div className='col-md-3'>
          <p style={{ fontSize: "24", fontWeight: "bolder" }}>
            {i18n.t("exploreBrands")}
          </p>
          {brandList.map((b, i) => (
            <p
              key={i}
              className='btnHover'
              onClick={() => {
                history.push({
                  pathname: "/category",
                  state: {
                    brandId: b.id,
                    brandName: b.text,
                  },
                });
                window.scrollTo(0, 0);
              }}
              style={{ fontSize: "12" }}
            >
              {b.text}
            </p>
          ))}
        </div>
        <div className='col-md-3'>
          <p style={{ fontSize: "24", fontWeight: "bolder" }}>
            {i18n.t("ourLocation")}
          </p>
          <p>{i18n.t("location")}</p>
          <iframe
            width='100%'
            height='50%'
            loading='lazy'
            allowFullScreen
            src='https://www.google.com/maps/embed/v1/place?key=AIzaSyCcWcZYyXrQcHiPX7H90-HvJP17frIpXs4&q=42 Abd El-Khalik Tharwat&zoom=15'
          ></iframe>
          <p>{i18n.t("weAccepts")}</p>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <img
              alt=''
              src='/assets/COD.png'
              width='45'
              height='35'
              className='ml-2  mt-2'
            />
            <img
              alt=''
              src='/assets/Visa.png'
              width='45'
              height='35'
              className='ml-2  mt-2'
            />
            <img
              alt=''
              src='/assets/MasterCard.png'
              width='45'
              height='35'
              className='ml-2  mt-2'
            />
            <img
              alt=''
              src='/assets/valu-logo.png'
              width='45'
              height='35'
              className='ml-2  mt-2'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./stickyNavBar.css";
import httpService from "../../../services/httpService";
import { useTranslation } from "react-i18next";

const StickyNavBar = (props) => {
  const { t, i18n } = useTranslation();

  const history = useHistory();
  const [navList, setNavList] = useState([]);

  useEffect(() => {
    httpService
      .get(`${process.env.REACT_APP_SERVER_URL}category/listmenu`)
      .then((res) => {
        setNavList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const openChildrenDetails = (category) => {
    history.push({
      pathname: "/category",
      state: { categoryId: category.id, categoryName: category.name },
    });
    window.scrollTo(0, 0);
  };

  const searchFilter = (value) => {
    props.handleSearchFilter(value);
    history.push({
      pathname: "/category",
      state: {
        search: value,
      },
    });
  };

  return (
    <div className='container-fluid sticky-nav'>
      <div className='row'>
        <div className='col-md-7 mt-1 nav-titles-sticky align-items-center'>
          <p
            className='btn text-light'
            onClick={() => {
              history.push("/");
              window.scrollTo(0, 0);
            }}
          >
            {i18n.t("home")}
          </p>
          {navList.map((item, i) => (
            <>
              {item.children.length !== 0 ? (
                <>
                  <div key={i} className='dropdown show'>
                    <p
                      className='btn text-light dropdown-toggle'
                      id='dropdownMenuLink'
                      data-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                      {item.name}
                    </p>
                    <div
                      className='dropdown-menu'
                      aria-labelledby='dropdownMenuLink'
                    >
                      {item.children.map((children, i) => (
                        <p
                          className='dropdown-item btnHover'
                          onClick={() => openChildrenDetails(children)}
                        >
                          {children.name}
                        </p>
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                <p
                  className='btn text-light'
                  onClick={() => openChildrenDetails(item)}
                >
                  {item.name}
                </p>
              )}
            </>
          ))}
          {/* <p
            className='btn text-light'
            onClick={() => {
              history.push("/servicelist");
              window.scrollTo(0, 0);
            }}
          >
            {i18n.t("services")}
          </p> */}
        </div>
        {localStorage.getItem("currentLan") === "en" ? (
          <div
            className='col-md-3 nav-titles-sticky'
            style={{
              backgroundColor: "#264D82",
              height: "4rem",
              marginLeft: "9%",
            }}
          >
            <img
              alt=''
              src='/assets/Search.png'
              width='20'
              height='20'
              style={{ alignSelf: "center" }}
            />
            <input
              type='text'
              name='name'
              placeholder={i18n.t("searchProduct")}
              className='inputStickyNav w-100 pl-3'
              onChange={(e) => searchFilter(e.target.value)}
            />
          </div>
        ) : (
          <div
            className='col-md-3 nav-titles-sticky'
            style={{
              backgroundColor: "#264D82",
              height: "4rem",
              marginRight: "13%",
            }}
          >
            <img
              alt=''
              src='/assets/Search.png'
              width='20'
              height='20'
              style={{ alignSelf: "center" }}
            />
            <input
              type='text'
              name='name'
              placeholder={i18n.t("searchProduct")}
              className='inputStickyNav w-100 pl-3'
              onChange={(e) => searchFilter(e.target.value)}
            />
          </div>
        )}
        {/* ////////////mob-view////////////////////// */}

        <div className='row'>
          <div
            className='navbar-header-sticky col-xs-4 mr-1'
            style={{ height: "2rem" }}
          >
            <button
              type='button'
              className='navbar-toggle collapsed-sticky mr-4'
              style={{ height: "2rem" }}
              data-toggle='collapse'
              data-target='#bs-example-navbar-collapse-2'
            >
              <img alt='' src='/assets/menu.png' className='ml-3' width='30' />
            </button>
          </div>
          <div className='col-xs-2 '></div>
          <div
            className='col-xs-6 search-sticky '
            style={{
              backgroundColor: "#264D82",
            }}
          >
            <img
              className='ml-3'
              alt=''
              src='/assets/Search.png'
              width='15'
              height='15'
            />
            <input
              type='text'
              name='name'
              placeholder={i18n.t("searchProduct")}
              className='inputStickyNav w-50 pl-3'
              onChange={(e) => searchFilter(e.target.value)}
            />
          </div>
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-2'
        >
          <div
            className='col-xs-8 mt-3 text-center '
            style={{
              display: "flex",
              flexDirection: "column",
              color: "white",
              backgroundColor: "#264D82",
            }}
          >
            <p
              className='btn text-light'
              onClick={() => {
                history.push("/");
              }}
            >
              {i18n.t("home")}
            </p>
            {navList.map((item, i) => (
              <>
                {item.children.length !== 0 ? (
                  <div key={i} className='dropdown show'>
                    <p
                      className='btn text-light  dropdown-toggle'
                      id='dropdownMenuLink'
                      data-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                      {item.name}
                    </p>
                    <div
                      key={i}
                      className='dropdown-menu'
                      aria-labelledby='dropdownMenuLink'
                    >
                      {item.children.map((children, i) => (
                        <p
                          className='dropdown-item'
                          onClick={() => openChildrenDetails(children)}
                        >
                          {children.name}
                        </p>
                      ))}
                    </div>
                  </div>
                ) : (
                  <p
                    className='btn text-light'
                    onClick={() => openChildrenDetails(item)}
                  >
                    {item.name}
                  </p>
                )}
              </>
            ))}
            {/* <p
              className='btn text-light'
              onClick={() => {
                history.push("/servicelist");
                window.scrollTo(0, 0);
              }}
            >
              {i18n.t("services")}
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StickyNavBar;

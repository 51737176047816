import axios from "axios";
// import { toast } from "react-toastify";

axios.interceptors.response.use(
  (res) => {
    return Promise.resolve(res);
  },
  (error) => {
    if (
      error.response.status === 401 &&
      localStorage.getItem("token") !== null
    ) {
      localStorage.removeItem("token");
      alert("Session timeout, Please login again");
    }

    const expectedErrors =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500;

    if (!expectedErrors) {
      console.log(error);
      // toast.error("An unexpected error");
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use((request) => {
  if (request.url.includes("qr")) {
    return request;
  } else {
    request.headers.common["lang"] = localStorage.getItem("currentLan");
    request.headers.common["authorization"] = localStorage.getItem("token")
      ? `bearer ${localStorage.getItem("token")}`
      : null;
    return request;
  }
  // add auth header with jwt if account is logged in and request is to the api url
});

export function setLang() {
  axios.defaults.headers.common["lang"] = localStorage.getItem("currentLan");
}
const exportedObject = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
};
export default exportedObject;
